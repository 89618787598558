@import '../../styles/shared';

$color-accordion-border: $transBlack !default;
$color-accordion__bg: $white !default;
$color-accordion-category: $dodgerBlue !default;
$nw-accordion--radius: 0 0 12px 12px;
$nw-accordion--margin: 15px !default;

.nw-accordion {
  padding: $based-padding;
  background: $color-accordion__bg;
  @include border-radius($nw-accordion--radius);
  box-shadow: 0 4px 12px -12px rgba(38,46,63,0.3);
  border-top: 1px solid rgba(84,84,84,0.6);
  font-size: $font-size--small;

  + .nw-accordion {
    margin-top: $common--space;
  }

  &__top {
    @include displayFlex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $nw-accordion--margin;
  }

  &__category {
    color: $color-accordion-category;
  }

  &__title {
    line-height: $line-height;
    margin-bottom: $nw-accordion--margin;
    font-weight: bold;
  }

  &__desc {
    max-height: 0;
    transition: $transition-animate;
    line-height: 20px;
    overflow: hidden;
  }

  &--open {
    .nw-accordion__desc {
      max-height: 300px;
    }

    svg {
      transform: rotate(180deg);
    }
  }

  svg {
    transition: $transition-animate;

    path {
      fill: $lightPink;
    }
  }
}