@import '../../styles/shared';

$color-box-title: $grey !default;

.nw-percent-box {
  @include displayFlex;
  flex-direction: column;
  justify-content: space-between;
  font-size: $font-size--small;
  flex: 1;

  &:not(:first-child) {
    padding-left: 20px;
  }

  &:not(:last-child) {
    padding-right: 20px;
    border-right: 1px solid $transBlack;
  }

  &__donut {
    margin-bottom: 12px;
    $donutSize: 26px;
    width: $donutSize;
    height: $donutSize;
  }

  &__text {
    @include displayFlex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
  }

  &__title {
    margin-bottom: 12px;
    color: $color-box-title;
  }

  &__percent {
    font-weight: $font-weight--bold;
    font-size: $font-size--base;
  }
}