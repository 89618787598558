@import '../../styles/shared.scss';

$color-card-border: $transBlack !default;
$color-card__bg: $white !default;
$nw-card--radius: 0 0 12px 12px;
$nw-card-padding-side: 20px 0px;

.nw-card {
  padding: $based-padding;
  background: $color-card__bg;
  @include border-radius($nw-card--radius);
  box-shadow: 0 4px 12px -12px rgba(38,46,63,0.3);
  border-top: 1px solid rgba(84,84,84,0.6);

  &--none-padding-side {
    padding: $nw-card-padding-side;
  }

  + .nw-card {
    margin-top: $common--space;
  }

  &__header {
    padding-bottom: $based-padding;
    margin-bottom: $based-padding;
    border-bottom: 1px solid $color-card-border;
    font-size: $font-size--small;

    // To overwrite subTitle when used insed card
    .nw-subTitle {
      min-height: 0;
      strong {
        font-weight: $font-weight--medium;
      }
    }

    &--flex {
      @include displayFlex;
      justify-content: space-between;
    }
  }

  + .nw-subTitle {
    margin-top: $common--space;
  }
}