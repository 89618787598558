@mixin text-size-adjust($size) {
    -webkit-text-size-adjust: $size;
	    -ms-text-size-adjust: $size;
}

// Curvy edges
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
          border-radius: $radius;
       -o-border-radius: $radius;
}

//appearance
@mixin appearance($value) {
    -webkit-appearance: $value;
        -moz-appearance: $value;
            appearance: $value;
}

// Background Size (cover, 100%)
@mixin bg-size($size) {
    -webkit-background-size: $size;
       -moz-background-size: $size;
         -o-background-size: $size;
            background-size: $size;
}

// Box Sizing (border-box, content-box, initial)
@mixin box-sizing($sizing) {
    -webkit-box-sizing: $sizing;
       -moz-box-sizing: $sizing;
            box-sizing: $sizing;
}

// Box shadow
@mixin box-shadow($value) {
    -webkit-box-shadow: $value;
       -moz-box-shadow: $value;
            box-shadow: $value;
}

// Order of element (which element you want to go first)
@mixin element-order($value) {
    -webkit-order: $value;
            order: $value;
}
  
// animation
@mixin animation($value) {
    -webkit-animation: $value;
       -moz-animation: $value;
        -ms-animation: $value;
         -o-animation: $value;
            animation: $value;
}

// to center child elements
@mixin center-elements() {
    @include displayFlex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-content: stretch;
    align-items: center;
    // background-position: center;
}

// to vertical align a child elements and align text to left
@mixin valign-elements() {
    @include displayFlex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

//e.g. @include calc(width, "25% - 1em");
@mixin calc($property, $expression) {
    #{$property}: -webkit-calc(#{$expression});
    #{$property}: calc(#{$expression});
}

// Calculate line-height
@mixin line-height($font-size, $line-height) {
    line-height:  $line-height / $font-size;
}

@mixin scaleTranslateY($sVal, $tVal) {
    transform: scale($sVal) translateY($tVal);
    -ms-transform: scale($sVal) translateY($tVal);
    -moz-transform: scale($sVal) translateY($tVal);
    -o-transform: scale($sVal) translateY($tVal);
    -webkit-transform: scale($sVal) translateY($tVal);
}

@mixin displayFlex() {
    display: -webkit-box;      /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;         /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;      /* TWEENER - IE 10 */
    display: -webkit-flex;     /* NEW - Chrome */
    display: flex;             /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

@mixin iPhoneX {
    @media only screen 
    and (min-device-width: #{$minWidthIphoneX}) 
    and (max-device-width: #{$maxWidthIphoneX}) 
    and (-webkit-min-device-pixel-ratio: 3)
    and (orientation: portrait) { 
        @content;
    }
}

@mixin desktop {
    @media only screen and (min-width: $minWidthDesktop) {
        @content;
    }
}

// Keyframe
@mixin keyframes($name) {
    @-webkit-keyframes #{$name} {
        @content
    }
    @-moz-keyframes #{$name} {
        @content
    }
    @-ms-keyframes #{$name} {
        @content
    }
    @-o-keyframes #{$name} {
        @content
    }
    @keyframes #{$name} {
        @content
    }
}
