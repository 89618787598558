@import '../../styles/shared';

$color-text: $white !default;
$color-text--lined: $black !default;
$padding-input: 15px !default;
$color-input-bg: $whiteTwo !default;

.nw-input {
  padding: 0 20px;
  min-height: $common--minHeight;
  color: $color-text--lined;
  outline: none;
  @include border-radius(24px);
  background: $color-input-bg;
  border: 1px solid $greyTwo;
  width: 100%;

  &--lined {
    background: none;
    border: none;
    @include border-radius(0);
    border-bottom: 0.5px solid rgba(255,255,255,0.7);
    color: $color-text;
    padding: $padding-input 0;
    letter-spacing: 0.5px;
  }

  &--invalid{
    border-color: $alertRed;
  }
}