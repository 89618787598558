// Common
$common--minHeight: 48px !default;
$common--space: 20px !default;
$common--fixedSpace: -18px !default;
$based-padding: 20px !default;
$base-margin: 20px !default;
$transition-animate: 500ms cubic-bezier(0.77, 0, 0.175, 1) !default;
$line-height: 24px !default;
$filter-width: 300px !default;

// Nav
$navbar--height: 64px !default;

// Border-radius
$border-radius--base: 5px !default; 
$border-radius--big: 10px !default;

// Font Weight
$font-weight--light: 300;
$font-weight--base: 400;
$font-weight--medium: 500;
$font-weight--bold: 700;
// $font-weight--bolder: 900;

// Text Size
$font-size-smaller: 12px;
$font-size--small: 14px;
$font-size--base: 16px;
$font-size--mid: 18px;
$font-size--big: 20px;
$font-size--title: 24px;
$font-size--bigger: 20px;

// Devices
$minWidthIphoneX: 375px;
$maxWidthIphoneX: 812px;
$minWidthDesktop: 813px;

// Box shadow
$boxShadow: 0 -3px 6px rgba(35,35,35,0.15) !default

$desktop-maxWidth: 400px;