@import '../../styles/shared';

.nw-debit-card {
    @include displayFlex;
    justify-content: space-between;

    .nw-input-box {
        max-width: 48%;
    }
}
