@import '../../styles/shared/';

$color-text-complete: $color-tertiary;
$color-button--complete: $offYellow !default;
$color-button--complete-text: $white !default;

.nw-page-payment-complete{
    &__orange{
        color: $color-text-complete;
    }

    button {
        margin-top: 20px;
    }

    .nw-button--complete{
        color: $color-button--complete-text;
        border-color: $color-button--complete;
        background-color: $color-button--complete;
    }

    h3{
        font-size: $font-size--base;
        margin: 20px 0px;
    }

    section{
        font-size: $font-size--small;
        p{
            margin: 0px;
        }
    }

    h4{
        text-align: center;
        font-weight: $font-weight--base;
        margin: 10px 0px 0px;
    }
}