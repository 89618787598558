@import '../../styles/shared';
$color-spinner: $color-primary;

.nw-spinner {
  position: relative;
  width: 100%;

  &__loading {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  &--spinning {
    min-height: 100%;
    .nw-spinner__loading {
      display: block;
    }
  }

  svg {
    circle {
      stroke: $color-spinner;
    }
  }
}