@import '../styles/shared';
$transition-time: 300ms !default;

// body {
//     background: $color-body__bg;
// }
// Common Links
a {
    text-decoration: none;
    color: $color-primary;
    &:hover {
        color: $color-primary--hover;
    }
    &:focus {
        color: $color-primary;
    }
}

section.transition-group {
    position: relative;

    & > div:first-child {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
    }
}

.forward-enter {
    transform: translateX(100%);

    &.forward-enter-active {
        transform: translateX(0);
        transition: all $transition-time ease-in;
    }

    + .backward-exit.backward-exit-active {
        transform: translateX(-100%);
    }
}

.forward-exit {
    transform: translateX(0);
    &.forward-exit-active {
        transform: translateX(-100%);
        transition: all $transition-time ease-in;
    }
}

.backward-enter {
    transform: translateX(-100%);

    &.backward-enter-active {
        transform: translateX(0);
        transition: all $transition-time ease-in;
    }

    + .forward-exit.forward-exit-active {
        transform: translateX(100%);
    }
}

.backward-exit {
    transform: translateX(0);
    &.backward-exit-active {
        transform: translateX(100%);
        transition: all $transition-time ease-in;
    }
}

.nw-display--flex {
    @include displayFlex;
}

// Chart legends or items
.recharts-default-legend {
    li {
        svg {
            position: relative;
            top: -2px;
        }
        + li {
            margin-left: $common--space;
        }
    }
}

// Remove spin on input type number
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}
input[type=number] {
    -moz-appearance: textfield; /* Firefox */
}