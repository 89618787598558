@import '../../styles/shared';

$color-title: $disabledGrey;
$color-active: $color-primary;
$color-card-border: $transBlack !default;
$color-disabled: $lightGreyBlue;
$color-disabled-bg: $paleGrey;
$color-list-bg: $white;
$color--focus: $color-tertiary;

.select-box {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid $color-card-border;

  h3{
    font-size: $font-size--small;
    color: $color-title;
    margin: 18px 0 5px;
    font-weight: normal;
  }

  &--focused {
    border-color: $color--focus;
  }
  
  &__current {
    position: relative;
    cursor: pointer;
    outline: none;
  }
  
  &__icon {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    width: 20px;
    opacity: 0.3;
    transition: 0.2s ease;
  }

  .select-box__list {
    max-height: 200px;
    overflow-y: scroll;
    
    &--active {
      opacity: 1;
      animation-name: none;
    }
  }

  .select-box__option {
    cursor: pointer;
  }
  
  .select-box__icon {
    &--active{
      transform: translateY(-50%) rotate(180deg);
    }
  }
  
  &__value {
    display: flex;
  }
  
  &__input {
    display: none;
    
    &:checked + .select-box__input-text {
      display: block;
    }
  }
  
  &__input-text {
    display: none;
    width: 100%;
    margin: 0;
    padding: 15px 0px;
    background-color: $white;
    border: none;
    line-height: 1;

    &:focus {
      outline: none;
      color: $color--focus;
    }
  }
  
  &__list {
    position: absolute;
    width: 100%;
    padding: 0;
    list-style: none;
    opacity: 0;
    margin: 0;
    background: $color-list-bg;
    z-index: 1;
    
    animation-name: HideList;
    animation-duration: 0.5s;
    animation-delay: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: step-start;
    box-shadow: 0 4px 12px -12px rgba(38,46,63,0.3);
  }
  
  &__option {
    display: block;
    padding: 15px;
    background-color: $white;
    
    &:hover,
    &:focus {
      background-color: $alabaster;
    }

    &--active {
      background: $color-active;
      color: $white;
    }

    &--disabled {
      background: $color-disabled-bg;
      color: $color-disabled;
    }
  }
}

@include keyframes(HideList){
    from {
    transform: scaleY(1);
    }
    to {
    transform: scaleY(0);
    }
}