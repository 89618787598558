@import '../../styles/shared';

$color-title: $dateGrey !default;
$color-title__bg: $alabaster !default;
$color__bg: $white !default;
$filter__header__size: 42px !default;
$filter__option__size: 54px !default;

.filter-box {
  background: $color__bg;
  padding-left: $based-padding;

  h3 {
    padding: 0 20px;
    min-height: $filter__header__size;
    @include displayFlex;
    align-items: center;
    margin: 0 0 0 #{-$based-padding};
    color: $color-title;
    background: $color-title__bg;
    text-transform: uppercase;
    font-size: $font-size--small;
    font-weight: normal;
    border-top: 1px solid $mercury;
    border-bottom: 1px solid $mercury;
  }

  .nw-option-box {
    padding-right: $based-padding;
    padding-top: 0;
    padding-bottom: 0;
    min-height: $filter__option__size;
  }

  &__options {
    font-size: $font-size--small;
    &--horizontal {
      padding-top: $based-padding;
      padding-bottom: $based-padding;
      white-space: nowrap;
      overflow-x: auto;

      span {
        display: inline-block;
        padding: 6px 19px;
        color: $dodgerBlue;
        border: 1px solid $dodgerBlue;
        @include border-radius(24px);

        & + span {
          margin-left: 10px;
        }

        &.filter-box__option--active {
          color: $white;
          background: $dodgerBlue;
        }
      }
    }
  }
}