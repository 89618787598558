@import '../../styles/shared';

//color
$color-title: $white;
$color-percent-up: $greenBlue !default;
// $home-header--margin: -20px -20px 20px -20px;

.nw-home-header {
  // padding-top: $common__space;
  padding-bottom: 20px;
  text-align: center;
  color: $color-title;
  background-color: $color-primary;
  background-image: url('../../assets/images/homeheader.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  margin-top: 0;
  margin-bottom: $base-margin;
  min-height: 240px;

  .nw-top-nav {
    margin: 0;
  }

  h1 {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: $font-size--small;
    font-weight: $font-weight--light;
    text-transform: uppercase;
    color: lighten($color-title, 20);
  }

  &__value {
    font-size: $font-size--bigger;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__change {
    @include displayFlex;
    justify-content: center;
    align-items: center;
    font-size: $font-size--small;

    span {
      margin-left: 5px;
    }

    &-percent {
      padding: 7px 15px;
      font-weight: $font-weight--light;
      background: $color-percent-up;
      @include border-radius(16px);
      margin-right: 15px;
    }
    &-value {
      font-weight: $font-weight--light;
    }
  }
}

.nw-homepage-body {
  padding: 0 $base-margin $base-margin;
  background: $color-body__bg;
}

.nw-home-tabs {
  margin-bottom: 30px;

  .tabs-menu__tabs {
    justify-content: center;
  }

  .tabs-menu__content {
    padding: 0;
  }

  .nw-button {
    width: 100%;
  }
}