@import '../../styles/shared';

$color-subTitle__label: $black;
$color-subTitle__link: $color-primary;
$color-subTitle__link--hover: $color-primary--hover;
$subTitle__spacing: 10px;

.nw-subTitle {
    @include displayFlex;
    align-items: center;
    justify-content: space-between;
    min-height: $common--minHeight;
    &--noTopSpace {
        margin-top: $common--fixedSpace;
    }
    strong {
        font-weight: $font-weight--bold;
    }
    a {
        color: $color-subTitle__link;
        text-decoration: none;
        &:hover, &:focus {
            color: $color-subTitle__link--hover;
        }
        svg {
            margin-left: $subTitle__spacing/2;
            path {
                fill: $color-body__icon;
            }
        }
    }
}