@import '../../styles/shared';

// $color-button: $dodgerBlue;

.nw-blue-link {
  background: none;
  border: none;
  color: $color-primary;
  font-size: $font-size--small;
  flex-shrink: 0;
  text-align: left;
  padding: 0;
}