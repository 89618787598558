@import '../../../styles/shared';

$nw-line-chart-filter-chart-height: 180px; 

.nw-line-chart-filter{
    width: 100%;
    @include displayFlex;
    flex-direction: column;
    align-items: center;

    &__filter-area{
        width: 100%;
        @include displayFlex;
        overflow-x: scroll;
        padding: 0px 5px;

        &__btn{
            &:not(:last-child){
                margin-right: 10px;            
            }

            .nw-button{
                margin-top: 0;
            }
        }

        @include desktop{
            justify-content: space-around;
        }
    }

    &__chart-area{
        width: 100%;
        height: $nw-line-chart-filter-chart-height;
        padding: 20px 20px 0px 20px;

        & > .nw-spinner {
            height: #{$nw-line-chart-filter-chart-height - 20px};
        }

    }
}