@import "./styles/shared";

.App {
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(224, 224, 224, 1) 90%,
    rgba(234, 234, 234, 1) 90%,
    rgba(255, 255, 255, 1) 100%
  );
  @include displayFlex;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;

  * {
    ::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &__body {
    background: $color-body__bg;
    min-height: 100vh;
    position: relative;
    scroll-behavior: smooth;
    width: 100%;

    &:before {
      position: absolute;
      content: "";
      background: $color-primary;
      width: 100%;
      height: 64px;
    }

    &--desktop {
      max-width: $desktop-maxWidth;
      min-height: 94vh;
      @include box-shadow(4px -2px 16px 0px rgba(0, 0, 0, 0.225));
      @include border-radius(24px);
      margin: auto;
      position: relative;
      overflow-x: hidden;

      &:before {
        display: none;
      }

      #update-notification {
        max-width: $desktop-maxWidth;
        left: 50%;
        transform: translateX(-50%);
      }

      .modal {
        max-width: $desktop-maxWidth;
        max-height: 94vh;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include border-radius(24px);
      }

      .nw-page {
        position: relative;
        .navbar {
          position: absolute;
        }
      }
    }
  }
}
