@import '../../../styles/shared';

$nw-tooltip-padding: 18px 20px;
$nw-tooltip-radius: 12px;
$nw-tooltip-shadow: 0px 4pt 18pt -12pt;
$nw-tooltip-margin: 15px;
$nw-tooltip-padding: 15px;


.nw-tooltip{
    background-color: $white;
    padding: $nw-tooltip-padding;
    
    @include border-radius($nw-tooltip-radius);
    @include box-shadow($nw-tooltip-shadow);

    p{
        margin: 0px;
    }

    header{
        border-bottom: 1px solid $color-border;
        padding-bottom: $nw-tooltip-padding;

        p{
            color: $lighterBlack;
        }
    }

    section{
        @include displayFlex;
        align-items: center;
        margin-top: $nw-tooltip-margin;

        p{
            font-weight: $font-weight--base;
            margin-bottom: 0;
            color: $greenBlue;
        }
    }
}

