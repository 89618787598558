@import '../../../styles/shared';

.my-portfolio {
    color: $color-body__text;

    &:hover, &:focus {
        outline: none;
        color: $color-body__text;
    }

    + .nw-subTitle {
        margin-top: $common__space;
    }
}