@import '../../styles/shared';

#update-notification {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    // max-width: $desktop-maxWidth;
    bottom: -100%;
    padding: 20px 20px 60px;
    color: $black;
    background: $white;
    z-index: 99999;
    transition: $transition-animate;
    text-align: center;
    font-size: 92%;
    @include box-shadow($boxShadow);

    .nw-spinner {
        display: none;
    }

    &.show {
        bottom: 0;
    }

    &.clicked {
        .nw-spinner {
            display: block;
        }

        button {
            & > span {
                display: none;
            }
        }
    }

    button {
        padding: 0 $based-padding;
        margin: $based-padding/2 auto;
        min-width: 128px;
        svg {
            width: 18px;
        }
    }
}