@import '../../styles/shared';

$color-title: $disabledGrey;
$color-card-border: $transBlack !default;
$color--focus: $color-tertiary;

.nw-input-box {
  border-bottom: 1px solid $color-card-border;

  &--invalid{
    border-color: $alertRed;
  }

  &--focused {
    border-color: $color--focus;
    color: $color--focus;

    input {
      color: $color--focus;
    }
  }

  h3 {
    &, span {
      font-size: $font-size--small;
      color: $color-title;
      margin: 18px 0 5px;
      font-weight: normal;
    }
  }

  & > div {
    position: relative;
  }

  input {
    border: none;
    padding: 15px 0;
    width: 100%;
    font-size: $font-size--base;

    &:focus {
      outline: none;
    }

    &:disabled {
      background: none;
       color: $color-form__input--disabled;
    }
  }

  &--currency {
    position: relative;
    font-size: $font-size--base;

    span {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    &.nw-input-box--disabled {
      color: $color-form__input--disabled;
    }

    input {
      padding-left: 30px;
      // &:disabled {
      //   color: $color-form__input--disabled;
      // }
    }
  }

  &__input {
      @include displayFlex;
      justify-content: space-between;
      align-items: center;

      &--card {
        position: relative;
        input {
          background: transparent;
        }
        &::after {
          position: absolute;
          content: url(../../assets/icons/visa.png);
          width: 40px;
          height: 20px;
          right: 0;
          bottom: 12px;
        }
      }
  
  }
}