@import '../../styles/shared';

$color-bg: $white !default;
$color-title: $grey !default;
$color-link: $deepSkyBlue !default;
$modal-padding: 10px !default;
$button-size--big: 54px;
$modal-content-padding: 15px !default;

.action-modal {
  position: fixed;
  z-index: 999;
  display: block;
  opacity: 0;
  width: 100%;
  height: 100vh;
  padding: $modal-padding;
  top: 0;
  left: 0;
  transition: $transition-animate;
  pointer-events: none;

  &--open {
    background: $modalOverlay;
    opacity: 1;
    pointer-events: auto;

    .action-modal__body {
      bottom: $modal-padding;
    }
  }

  &__body {
    transition: $transition-animate;
    margin-top: auto;
    text-align: center;
    width: calc(100% - #{$modal-padding*2});
    position: absolute;
    left: $modal-padding;
    bottom: -100%;
  }

  &__top,
  &__cancel {
    background: $color-bg;
    @include border-radius(13px);
    width: 100%;
  }

  &__top {
    margin-bottom: 10px;
  }

  &__title {
    font-size: $font-size--small;
    color: $color-title;
    padding: $modal-content-padding;
    border-bottom: 1px solid $transBlack;
  }

  &__content {
    // padding: $modal-content-padding;
    a, span {
      color: $color-link;
    }
    a {
      @include displayFlex;
      align-items: center;
      justify-content: center;
      min-height: $button-size--big;
    }
  }

  &__cancel {
    font-weight: bold;
    color: $color-link;
    padding: $modal-content-padding;
    border: none;
    outline: none;
    min-height: $button-size--big;
  }
}