@import '../../styles/shared';

.nw-itemList {
    width: 100%;
    margin: 0;
    font-size: $font-size--small;
}

// adjust to top when container is a Card
.nw-card .nw-card__body {
    > .nw-itemList {
        margin-top: -18px;
    }
}