@import '../../styles/shared';

$color__gauge: $lightSkyBlue;
$color__gauge--current: $greenBlue;
$color__borderColor: $veryLightPink;
$gauge-height: 6px;

.investment-analysis {
    width: 100%;
    font-size: $font-size--small;
}

.gauge-item {
    @include displayFlex;
    justify-content: space-between;
    align-items: center;
    min-height: $common--minHeight;
    span {
        display: block;
    }
    &__value {
        min-width: 50%;
        height: $gauge-height;
        background: $color__gauge;
        @include border-radius($gauge-height);
        overflow: hidden;
        span {
            background: $color__gauge--current;
            height: $gauge-height;
        }
    }
    + .gauge-item {
        border-top: 1px solid $color__borderColor;
    }
}

.nw-item + .gauge-item {
    border-top: 1px solid $color__borderColor;
}

// adjust to top when container is a Card
.nw-card .nw-card__body {
    > .investment-analysis {
        margin-top: $common--fixedSpace;
    }
}