@import '../../styles/shared';

.transaction-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  transition: $transition-animate;
  opacity: 0;
  pointer-events: none;

  &--open {
    opacity: 1;
    background: $modalOverlay;
    pointer-events: auto;

    .transaction-filter__container {
      right: 0;
    }
  }

  &__container {
    height: 100vh;
    width: $filter-width;
    box-shadow: 4px 0 20px rgba(21,24,30,0.1);
    background: $white;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    right: -100%;
    transition: $transition-animate;
  }

  .nw-button {
    margin: 30px auto;
    width: calc(100% - #{$based-padding * 2});
  }

  .filter-box__options--horizontal {
    padding-right: $based-padding;
  }
}