@import '../../styles/shared/';

$nw-transaction--margin: $base-margin;
$nw-transaction-list--padding: 17px;

$item--borderColor: $veryLightPink;

.nw-transaction{
    // margin-top: $nw-transaction--margin;

        width: 100%;

        .nw-transaction__list{
            &:not(:first-child){
                padding-top: $nw-transaction-list--padding;
            }
    
            &:not(:last-child){
                border-bottom: 1px solid $item--borderColor;
                padding-bottom: $nw-transaction-list--padding;
            }
        }
}