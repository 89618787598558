$dodgerBlue:    #3485ff;
$greenBlue:     #00c693;
$veryLightPink: #f3f3f3;
$orangeyRed:    #ff4133;
$alertRed:      #db3b21;
$black:         #000000;
$lighterBlack:  #454545;
$white:         #ffffff;
$whiteTwo:      #f7f7f7;
$offYellow:     #fcd53b;
$paleGrey:      #efeff4;
$lightBlueGrey: #d1d1d6;
$lightSkyBlue:  #dde3e9;
$paleLilac:     #e5e5ea;
$lightGreyBlue: #afb9c5;
$lightPink:     #bebebe;
$grey:          #999999;
$transBlack:    rgba(0,0,0,0.1);
$buttonGrey:    #bebebe;
$menuGrey:      #eaeaea;
$dateGrey:      #404859;
$reddishOrange: #f45f32;
$lightOrange:   #fca33b;
$paleTeal:      #89c5cc;
$robinSEgg:     #5ac8fa;
$warmBlue:      #5856d6;
$deepSkyBlue:   #007aff;
$greyishBrown:  #545454;
$alabaster:     #FBFBFB;
$mercury:       #E6E6E6;
$modalOverlay:  rgba(0,0,0,0.4);
$greyTwo:       #E7E7E7;
$greyThree:     #FAFAFA;
$ebonyClay:     #262E3F;
$redError:      #e74c3c;
$loadingBlue:    rgba(52,133,255,0.5);
$moreBlurBlack:  rgba(0,0,0,0.08);
$completeGreen: #1AC735;
$disabledGrey:  rgba(38,46,63,0.7);
$transWhite:    rgba(255,255,255,0.8);

// Global Common Colors
$color-body__bg: $whiteTwo !default;
$color-body__text: $black !default;
$color-body__text--inverted: $white !default;
$color-body__subtext: $grey !default;
$color-body__icon: $lightPink !default;

// Forms
$color-form__input: $color-body__text;
$color-form__input--disabled: $disabledGrey !default;

$color-border: $paleLilac !default;

$color-primary: $dodgerBlue !default;
$color-primary--hover: darken($color-primary, 20) !default;

$color-secondary: $greenBlue !default;
$color-secondary--hover: darken($color-secondary, 20) !default;

$color-tertiary: $reddishOrange !default;
$color-tertiary--hover: darken($color-tertiary, 20) !default;

$color-header__bg: $dodgerBlue !default;

$color-checkmark--checked: $dodgerBlue !default;