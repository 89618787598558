@import '../../styles/shared';

$page--height: 100vh;
$page--navColor: $color-primary;

.nw-page {
    height: $page--height;
    &__header {
        height: $navbar--height;
        background-color: $page--navColor;
        background-image: url('../../assets/images/navbar.png');
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        
    }
    &--transparent-header {
        .nw-page__header {
            background-image: none;
        }
    }
    &__body {
        @include calc(height, #{$page--height} - #{$navbar--height});
        @include calc(max-height, #{$page--height} - #{$navbar--height});
        background: $color-body__bg;
        overflow-y: auto;
        padding: $common--space $common--space $common--space*3;
        @include displayFlex;
        flex-direction: column;
        scroll-behavior: smooth;
    }
}