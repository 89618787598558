@import '../../styles/shared';

$color-tab-menu--active: $dodgerBlue !default;
$tabs-height: 32px !default;

.tabs-menu {
  & > div {
    padding: 20px 15px;
  }

  &--open {
    bottom: 0;

    &::before {
      opacity: 1;
    }
  }

  &__tabs {
    @include displayFlex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    padding-left: 0;
    margin-top: 0;
    margin-bottom: 20px;
    transition: transform 0.5s ease-out;

    li {
      @include displayFlex;
      min-height: $tabs-height;
      align-items: center;
      list-style: none;
      padding: 0 15px;
      color: $color-tab-menu--active;
      @include border-radius(26px);
      cursor: pointer;
      border: 1px solid transparent;
      -webkit-tap-highlight-color: transparent;

      &.tabs-menu--active {
        border: 1px solid $color-tab-menu--active;
      }

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  &__content {
    & > div {
      display: none;
    }

    &--active {
      display: block !important;
      animation-name: fadeIn;
      animation-iteration-count: 1;
      animation-timing-function: ease-in;
      animation-duration: 0.5s;
    }

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  }
}