@import '../../styles/shared';

$color-link: $dodgerBlue;

.agreement{
    display: block;
    margin-top: 30px;

    article {
        @include displayFlex;
        // align-items: center;
        font-size: $font-size--small;
    }

    &__checkbox{
        align-self: flex-start;
        margin-right: ($common--space)-5;
    }

    &__text{
        flex-grow: 1;

        p{
            margin: 0px;
            line-height: 24px;
        }

        h4{
            font-weight: $font-weight--base;
            color: $color-link;
            margin: 10px 0px 0px;
        }
    }
}
