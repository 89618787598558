@import '../../styles/shared.scss';

.nw-page-position {
  .nw-card-position {
    padding-bottom: 45px;

    .nw-card__body {
      height: 200px;
    }
  }

  .nw-button + .nw-card {
    margin-top: $base-margin;
  }
}

.investment-actions {
  @include displayFlex;
  justify-content: space-between;
  margin-bottom: $base-margin;
  min-height: $common--minHeight;

  .nw-button {
    margin-top: 0;
    flex-grow: 1;
    + .nw-button {
      margin-left: $base-margin;
    }
  }
}