@import '../../styles/shared';

$donut--size: 30%;
$donut--height: 100px;

.donut-chart-list {
    width: 100%;
    height: auto;
    @include displayFlex;
    justify-content: space-between;
    flex-direction: row;
    &--list-below {
        flex-direction: column;
        .donut-chart-list__items {
            margin-top: $common__space/2;
        }
    }
    &__chart {
        position: relative;
        min-width: $donut--size;
        max-width: $donut--size;
        height: $donut--height;
        margin-right: $common__space;
        padding: 0 $common__space/4;
    }
    &__items {
        flex-grow: 1;
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            position: relative;
            > div {
                @include displayFlex;
                justify-content: space-between;
                padding: $common__space/2 0;
                + .donut-chart-list__items__identifier {
                    position: absoltue;
                    display: block;
                    width: 40px;
                    $height: 3px;
                    height: $height;
                    @include border-radius($height);
                }
            }
            + li {
                margin-top: $common__space/2;
            }
        }
    }
}