@import '../../styles/shared';

$icon-size: 96px !default;

.nw-icon-button {
  @include displayFlex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__icon {
    width: $icon-size;
    height: $icon-size;
    margin-bottom: 10px;

    img {
      width: 100%;
      height: auto;
    }

    &--active {
      display: none;
    }
  }

  &--active {
    .nw-icon-button__icon {
      display: none;

      &--active {
        display: block;
      }
    }
  }

  span {
    display: inline-block;
    padding: 6px 20px;
    border: none;
    border-top: 1px solid $veryLightPink;
    background: $white;
    z-index: 1;
    margin-top: -25px;
  }
}