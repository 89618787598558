@import '../../styles/shared';
$color-date: $greyishBrown !default;
$color-warning: $reddishOrange !default;
$margin-bottom: 15px !default;

.risk-profiling-card {
  .nw-card__body {
    display: block;
    font-size: $font-size--small;
  }

  h2 {
    margin-top: 0;
    font-size: $font-size--title;
    margin-bottom: $margin-bottom;
    text-transform: capitalize;
  }

  &__date {
    color: $color-date;
    margin-bottom: $margin-bottom;
  }

  &__warning {
    color: $color-warning;
    margin-bottom: $margin-bottom;
  }

  p {
    line-height: 24px;
  }

  h4{
    margin: 15px 0px;
  }

}

.nw-button {
  margin-top: 30px;
  width: 100%;
}
