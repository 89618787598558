@import '../../styles/shared';

$color-button: $reddishOrange;
$color-button--primary: $color-primary !default;
$color-button--disabled: $paleGrey !default;
$color-button--disabled-text: $lightGreyBlue !default;

.nw-page-sellFund {

  .nw-card {
    &__header {
      font-weight: bold;
    }
  }

  &__tos {
    line-height: $line-height;
    align-items: flex-start;

    .nw-option-box__text {
      text-transform: none;
    }
  }

  .nw-blue-link {
    margin-left: 44px;
  }


  .nw-redemption-account {
    @include displayFlex;
    justify-content: space-between;

    .nw-input-box {
      width: 50%;

      & + .nw-input-box {
        margin-left: 15px;
      }
    }
  }

  .nw-button {
    width:100%;
  }

  .nw-item {
    &--nav {
      border-bottom: 2px solid $transBlack;
    }

    &--currentValue {
      .nw-item__value {
        color: $color-secondary;
      }
    }
  }
}