@import '../../styles/shared';

$input-width: 46px;
$input-height: 48px;

.nw-input-group {
  @include displayFlex;
  justify-content: center;

  input {
    width: $input-width;
    height: $input-height;
    border: 1px solid $veryLightPink;
    text-align: center;
    font-weight: bold;

    & + input {
      margin-left: 10px;
    }

  }
}