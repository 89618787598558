@import '../../styles/shared';

$margin-top: 30px;

.nw-page-otp {
  text-align: center;
  line-height: $line-height;

  .nw-page__body {
    padding-top: 60px;
  }

  p {
    color: $greyishBrown;
    margin: 0;
  }

  .nw-input-group {
    margin-top: $margin-top;
  }

  button {
    background: none;
    border: none;
    color: $dodgerBlue;
    margin-top: $margin-top;
    outline: none;
  }
}