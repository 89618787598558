@import '../../styles/shared';
$color-category: $dodgerBlue !default;

.question-box {
  .nw-card__body {
    flex-direction: column;
    font-size: $font-size--small;
  }

  &__category {
    color: $color-category;
    margin-bottom: $base-margin/2;
  }

  h2 {
    margin: 0;
    font-size: $font-size--small;
    line-height: $line-height;
  }

  &__answers {
    line-height: 20px;

    .nw-option-box {
      justify-content: flex-end;
      align-items: flex-start;

      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
    }
  }
}