@import '../../styles/shared';

$fund-summary-padding: 20px;
$fund-summary-margin: 20px;

$fund-summary-images-size: 55px;

$fund-summary-color-light-black: $lighterBlack;

.fund-summary{
    @include displayFlex;
    flex-direction: column;
    width: 100%;

    &__header{
        @include displayFlex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: $fund-summary-padding;

        &__image{
            background-size: cover;
            background-color: $dateGrey;
            width: $fund-summary-images-size;
            height: $fund-summary-images-size;
            @include border-radius(50%);
            margin-right: $fund-summary-margin;
        }

        &__fund{
            flex: 1;
            p{
                margin: 0px 0px 8px;
                color: $color-body__subtext;
            }
            h4{
                margin: 9px 0px 0px;
                font-size: $font-size--small;
            }
        }

    }

    &__description{
        p{
            margin: 20px 0px;
        }
        border-top: 1px solid $color-border;
    }

    &__value{

        ul{
            @include displayFlex;
            justify-content: space-between;
            border-top: 1px solid $color-border;
            padding-top: $fund-summary-padding;

            list-style-type: none;
            padding-inline-start: 0px;
            margin-block-start: 0;
            margin-block-end: 0;


            &:not(:last-child){
                padding-bottom: $fund-summary-padding;
            }

            p{
                margin: 0px 0px 5px;
                color: $fund-summary-color-light-black;
            }

            li{
                width: 50%;
                .fund-summary__value__detail{
                    @include displayFlex;
                    align-items: center;
    
                    h4{
                        margin: 0px;
                        text-indent: 10px;
                    }
                }

                &:last-child{
                    padding-left: $fund-summary-padding;
                }
            }
            
        }
    }
}