@import '../../styles/shared';

$color-progress: $dodgerBlue !default;
$color--warning: $reddishOrange !default;

.nw-progress-bar {
  font-size: $font-size--small;

  &__percent {
    color: $color-progress;
    margin-bottom: $base-margin;
  }

  &__track {
    width: 100%;
    height: 4px;
    background: $greyThree;
    position: relative;
    margin-bottom: $base-margin;
  }

  &__progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: $color-progress;
  }

  &__description {
    &--warning {
      color: $color--warning;
    }
  }
}