@import '../../styles/shared';
@import "~animatewithsass/animate.scss";

// Colors
$color-button--primary: $color-primary !default;
$color-button--secondary: $color-secondary !default;
$color-button--tertiary: $color-tertiary !default;
$color-button--disabled: $paleGrey !default;
$color-button--disabled-text: $lightGreyBlue !default;
$color-button--text: $white !default;
$color-button--filter: $lighterBlack !default;
$border-color-button--filter: $color-border !default;

// Variables
$button-size--small: 32px;
$button-size--base: $common--minHeight;
$button-size--big: 54px;
$button-padding: 0 20px;
$button-textsize--small: $font-size--small !default;
$button-textsize--base: $font-size--base !default;
$button-textsize--big: $font-size--big !default;
$button-min-width-filter: 55px;

.nw-button {
    display: block;
    min-height: $button-size--base;
    background: inherit;
    color: inherit;
    border: 1px solid transparent;
    text-align: center;
    line-height: initial;
    font-weight: $font-weight--base;
    font-size: $button-textsize--base;
    padding: $button-padding;
    @include border-radius($button-size--base);
    // @include appearance(none);

    &:focus, &:active {
        outline: none;
    }

    &--primary {
        background: $color-button--primary;
        border-color: $color-button--primary;
        color: $color-button--text;

        &.nw-button--outline {
            background: transparent;
            color: $color-button--primary;
        }

        &.nw-button--selected {
            position: relative;
            &:before {
                position: absolute;
                content: url(../../assets/icons/checkOn.png);
                $iconSize: 32px;
                width: $iconSize;
                height: $iconSize;
                left: $common--space/2;
                top: 11px;
                @include fadeIn();
            }
        }
        // &.nw-button--disabled {
        //     background: lighten($color-button--primary, 30);
        //     color: $color-button--text;
        // }
    }
    &--secondary {
        background: $color-button--secondary;
        color: $color-button--text;
        border-color: $color-button--secondary;
        &.nw-button--outline {
            background: transparent;
            color: $color-button--secondary;
        }
    }
    &--tertiary {
        background: $color-button--tertiary;
        color: $color-button--text;
        border-color: $color-button--tertiary;
        &.nw-button--outline {
            background: transparent;
            color: $color-button--tertiary;
        }
    }
    &--disabled {
        background: $color-button--disabled;
        color: $color-button--disabled-text;
        border-color: $color-button--disabled;
        cursor: not-allowed;
    }
    &--small {
        min-height: $button-size--small;
        font-size: $button-textsize--small;
        @include border-radius($button-size--small);
    }
    &--big {
        min-height: $button-size--big;
        font-size: $button-textsize--big;
        @include border-radius($button-size--big);
    }
    &--filter{
        @include border-radius($button-size--small);
        font-size: $button-textsize--small;
        min-height: $button-size--small;
        min-width: $button-min-width-filter;
        background: transparent;
        color: $color-button--filter;
        border-color: $border-color-button--filter;

        padding: 0px;

        @include iPhoneX{

            &:first-child{
                margin-left: 4px;
            }
    
            &:last-child{
                margin-right: 4px;
            }
        }

        &--active{
            color: $white;
            background-color: $black !important;
        }
    }

    &--fullwidth{
        width: 100%;   
    }
}