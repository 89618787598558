@import '../../styles/shared';

$line-margin: $base-margin !default;

.nw-card-goals {
  .nw-display--flex {
    flex-wrap: wrap;
    margin-top: -$line-margin;

    & > * {
      margin-top: $line-margin;
    }

    .nw-icon-button {
      width: 50%;
    }
  }
}