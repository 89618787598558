@import '../../styles/shared.scss';

$color-card-stat: $greenBlue !default;
$button-size: 20px !default;

.nw-card__header-withIcon {
  @include displayFlex;

  &-icon {
    width: 50px;
    height: 50px;
    margin-right: 15px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &-title {
    font-size: $font-size--small;
    font-weight: $font-weight--light;
    margin-bottom: 5px;
  }

  &-subTitle {
    font-size: $font-size--mid;
    font-weight: $font-weight--bold;
    margin-bottom: 10px;
  }

  &-desc {
    color: $color-card-stat;
    span {
      margin-left: 5px;
    }
  }

  &-button svg {
    min-width: $button-size;
    height: $button-size;

    path {
      fill: $buttonGrey;
    }
  }
  &-button img {
    width: $button-size+6;
    height: auto;
  }
}

.nw-card__header-text {
  @include displayFlex;
  justify-content: space-between;
  flex: 1;
  margin-top: 5px;
}