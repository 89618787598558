@import '../../../styles/shared';

$color-card-border: $transBlack !default;

.nw-my-goals {
  .nw-progress-bar {
    padding-bottom: $based-padding;
    margin-bottom: $based-padding;
    border-bottom: 1px solid $color-card-border;
  }

  .nw-my-goals__child:nth-child(even){
    margin-top: 20px;
  }
}