@import '../../styles/shared.scss';

$item--borderColor: $veryLightPink !default;
$color-checkmark--uncheck: $greyTwo !default;
$color-checkmark--checked: $dodgerBlue !default;
$color-text: $dateGrey !default;

.nw-option-box {
  @include displayFlex;
  justify-content: space-between;
  align-items: center;
  padding: $based-padding 0;
  border-bottom: 1px solid $item--borderColor;
  user-select: none;
  position: relative;
  color: $color-text;
  -webkit-tap-highlight-color: transparent;

  input {
    display: none;

    &[type=radio]:checked + span + span {
      path {
        fill: $color-checkmark--checked;
      }
    }
  }

  &__text {
    text-transform: capitalize;
  }

  &__checkmark {
    &--checked{
      svg {
        min-width: 24px;
        height: 24px;

        path {
          fill: $color-checkmark--checked;
        }
      }
    }
    svg {
      min-width: 24px;
      height: 24px;

      path {
        fill: $color-checkmark--uncheck;
      }
    }
  }

  &--left {
    flex-direction: row-reverse;

    input + span {
      margin-left: $base-margin;
    }
  }
}