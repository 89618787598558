@import '../../styles/shared';

$color-menu__top-bg: $menuGrey !default;
$color-menu__bg: $white !default;
$color-text: $dateGrey !default
$color-language-switcher__bg: $dodgerBlue !default;

.nw-menu {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  transition: $transition-animate;
  opacity: 0;
  pointer-events: none;
  z-index: 1;

  &__container {
    left: -100%;
    position: absolute;
    min-height: 100vh;
    width: 280px;
    box-shadow: 4px 0 20px rgba(21,24,30,0.1);
    background: $color-menu__bg;
    color: $color-text;
    transition: $transition-animate;
  }

  svg path {
    fill: $dateGrey;
  }

  h2 {
    font-size: $font-size--base;
  }

  &--open {
    opacity: 1;
    pointer-events: auto;
    background: $modalOverlay;

    .nw-menu__container {
      left: 0;
    }
  }

  &__top {
    padding: $based-padding * 2;
    background: $color-menu__top-bg;
    text-align: center;
    border-bottom: 1px solid $veryLightPink;
  }

  &__name {
    margin-bottom: 10px;
    font-weight: bold;
  }

  &__date {
    font-size: $font-size-smaller;
  }

  &__bottom {
    ul {
      padding-left: 0;
      margin: 0 0 0 $based-padding;

      li {
        list-style: none;
        padding-right: $based-padding;
        border-bottom: 1px solid $veryLightPink;
        height: 60px;
        @include displayFlex;

        a {
          @include displayFlex;
          align-items: center;
          flex-grow: 1;
          color: $color-text;
          text-transform: capitalize;
        }
      }
    }

    svg {
      margin-right: 10px;
    }
  }

  &__item {
    &--current {
      font-weight: bold;
    }

    &--flex {
      @include displayFlex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
    }
  }

  &__language {
    @include displayFlex;
    justify-content: space-between;
    align-items: center;
    background: $color-language-switcher__bg;
    @include border-radius(16px);
    padding: 2px;

    span {
      background: $dodgerBlue;
      padding: 5px 0;
      color: $white;
      @include border-radius(16px);
      font-size: $font-size--small;
      width: 34px;
      text-align: center;

      & + span {
        margin-left: 3px;
      }

      &.nw-menu__language--active {
        background: $white;
        color: $dodgerBlue;
      }
    }
  }
}