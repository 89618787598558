@import '../../styles/shared';

$item--minHeight: $common--minHeight;
$item--borderColor: $veryLightPink;
$color-date: $color-body__subtext;

.nw-item {
    min-height: $item--minHeight;
    @include displayFlex;
    font-size: $font-size--small;
    // padding: $common--space/2 0;
    > div  {
        @include displayFlex;
        justify-content: space-between;
        align-items: center;
        color: $color-body__text;
        width: 100%;
    }

    &--withNotes {
        flex-direction: column;
        padding: 14px 0;
        small {
            $spacing: 5px;
            margin-top: $spacing;
            color: $color-body__subtext;
            strong {
                color: $color-body__text;
            }
            strong + span {
                margin-left: $spacing;
            }
            span + span {
                margin-left: $spacing;
            }
            .stat {
                color: $color-secondary;
            }
        }
    }

    &--colored {
        position: relative;

        i {
            position: absolute;
            bottom: -21px;
            width: 40px;
            height: 5px;
        }
    }

    &__value {
        font-weight: $font-weight--medium;
    }
    + .nw-item {
        border-top: 1px solid $item--borderColor;
    }
    &:focus, &:visited {
        color: $color-body__text;
        outline: none;
    }
    > span:last-child {
        font-weight: $font-weight--medium;
    }

    span.date {
        color: $color-date;
    }
}

.nw-card > .nw-card__body {
    > .nw-item:first-child {
        min-height: 0;
        padding-bottom: 16px;
    }
}
.nw-card .nw-card__header {
    > .nw-item {
        min-height: 0;
        span:first-child {
            font-weight: $font-weight--bold;
        }
    }
}