@import '../../styles/shared';

$color-text: $white !default;
$padding-input: 15px !default;

.login-page {
  padding: $based-padding;
  min-height: 100vh;
  background: url('../../assets/images/backgroundLogin@2x.jpg') no-repeat center /cover;
  color: $color-text;

  .tabs-menu {
    &__tabs {
      li {
        color: $color-text;
        padding: 6px 28px;

        &.tabs-menu--active {
          border: 1px solid $color-text;
        }
      }
    }

    &__content {
      padding: 0;
      transform: translateY(50%);

      h2 {
        color: $color-text;
        margin-top: 0;
        margin-bottom: 40px;
        font-size: $font-size--title;
      }

      label {
        @include displayFlex;
        flex-direction: column;
        margin-bottom: 30px;

        span {
          color: $color-text;
        }

        & > span {
          opacity: 0.7;
          margin-bottom: 5px;
        }
      }

      .nw-button {
        background: $color-text;
        color: $dodgerBlue;
        font-weight: bold;
        float: left;
      }
    }
  }
}

.login-tab {
  &__password {
    position: relative;

    input {
      padding-right: 60px;
    }

    a {
      position: absolute;
      right: 0;
      bottom: $padding-input;

      span {
        text-transform: uppercase;
      }
    }
  }

  .nw-button {
    width: 100%;
    margin-top: 15px;
  }
}