@import '../../styles/shared/';

// this one for mutual fund layout
$nw-mutual-fund-margin: $base-margin;
$nw-mutual-fund-radius: 12px;
$nw-mutual-fund-container-background: $white;
$nw-mutuan-fund--textColor: $greyishBrown;

//this one for mutual fund banner
$nw-mutual-fund-banner-height: 140px;
$nw-mutual-fund-banner-width: 100%;
$nw-mutual-fund-banner-bg: $black;

//this one for mutual fund header status
$nw-mutual-fund-status-background: $white;
$nw-mutual-fund-status-radius: 0px 22px 22px 0px;
$nw-mutual-fund-status-min-width: 140px;
$statu-icon-maxSize: 32px;

//this one for mutual fund header closed
$nw-mutual-fund-close-background: rgba(0,0,0,.8);
$nw-mutual-fund-close-radius: 22px 0px 0px 22px;
$nw-mutual-fund-close-font-size: $font-size--small;
$nw-mutual-fund-close-color: $white;

//this one for mutual fund content
$nw-mutual-fund-content-padding: $based-padding;

//this one for mutual fund value item
$nw-mutual-fund-items-text-margin: 0px 10px 10px 0px;

.nw-mutual-fund {
    background-color: $nw-mutual-fund-container-background;
    @include border-radius($nw-mutual-fund-radius);
    overflow: hidden;

    &:not(:first-child){
        margin-top: $nw-mutual-fund-margin;
    }

    &:not(:last-child){
        margin-bottom: $nw-mutual-fund-margin;
    }

    &__header {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        background-color: lighten($nw-mutual-fund-banner-bg, 25);
        height: $nw-mutual-fund-banner-height;
        width: $nw-mutual-fund-banner-width;

        &__group{
            @include displayFlex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
            padding-top: 30px;

            &__status{
                @include displayFlex;
                align-items: center;
                justify-content: space-between;
                // min-width: $nw-mutual-fund-status-min-width;
                min-height: $statu-icon-maxSize;
                background-color: $nw-mutual-fund-status-background;
                @include border-radius($nw-mutual-fund-status-radius);
                padding-left: 16px;
                padding-right: $statu-icon-maxSize+10;
                position: relative;
                img {
                    position: absolute;
                    right: -2px;
                    width: $statu-icon-maxSize;
                    height: $statu-icon-maxSize;
                }
            }

            &__status-btn{
                position: relative;

                .nw-button{
                    position: relative;
                    right: 20px;
                    margin-top: 0px;
                    font-size: $font-size--base;
                }

                &__closed{
                    @include displayFlex;
                    align-items: center;
                    justify-content: center;
                    top: 42px;
                    @include border-radius($nw-mutual-fund-close-radius);
                    background-color: $nw-mutual-fund-close-background;
                    color: $nw-mutual-fund-close-color;
                    font-size: $nw-mutual-fund-close-font-size;
        
                    position: relative;
                    padding: 0px 10px 0 20px;
        
                    img{
                        position: absolute;
                        top: 22px;
                        left: -10px;
                        color: $black;
                        max-width: 50px;
                    }

                    p{
                        margin: 10px 0px;
                        font-size: $font-size--small;
                    }
                }
            }
        }
    }

    section {
        @include displayFlex;
        flex-direction: column;
        padding: $nw-mutual-fund-content-padding+4 $nw-mutual-fund-content-padding;

        .nw-mutual-fund__navigate{
            justify-content: space-between;
            @include displayFlex;
            align-items: center;

            img {
                max-width: 28px;
            }
        }

        h3{
            text-align: left;
            margin: 18px 0px;
            font-size: $font-size--mid;
        }

        h4{
            margin: 0px;
            font-size: $font-size--base;
            text-align: left;
            color: $nw-mutuan-fund--textColor;
            font-weight: $font-weight--base;
        }

        ul{
            @include displayFlex;
            justify-content: space-between;
            padding: 0;
            margin-bottom: 0;
            list-style-type: none;
            // padding-inline-start: 0px;
            // margin-block-end: 0px;
            // margin-block-start: 25px;

            li{
                @include displayFlex;
                flex-direction: column;
                // font-size: $font-size--small;
                font-size: $font-size--base;

                p{
                    margin: $nw-mutual-fund-items-text-margin;
                    font-weight: $font-weight--bold;
                }
                
                .nw-mutual-fund__analytics{
                    @include displayFlex;
                    justify-content: flex-start;
                    align-items: center;

                    img{
                        $size: 16px;
                        max-width: $size;
                        max-height: $size;
                        margin-right: 5px;
                    }

                    h4{
                        font-weight: $font-weight--base;
                        font-size: $font-size--small;
                        color: $nw-mutuan-fund--textColor;
                    }
                }

            }
        }

    }
}