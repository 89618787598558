@import '../../styles/shared/';

$box-shadow: 0px 4pt 18pt -12pt;
$moreBlurBlack:  rgba(0,0,0,0.08);

.nw-fund-factsheet-page{
    padding-bottom: 64px;

    .fund-summary{
        .fund-summary__description{
            section{
                @include displayFlex;

                margin-top: 20px;

                span{
                    color: $greenBlue;
                    margin: 0px 5px;
                }

                h4{
                    font-weight: $font-weight--base;
                    margin: 0;
                }
            }
            p{
                line-height: 21px;
            }
        }
    }

    &__performance{
        min-height: 84px;
        position: absolute;
        bottom: 0px;
        left: 0px;
        width: 100%;
        background-color: $white;
        @include box-shadow($box-shadow);
        @include displayFlex;
        flex-direction: column;
        align-items: center;

        p{
            color: $dodgerBlue;
        }

        span{
            width: 20px;
            height: 2px;
            margin-top: 10px;
            background-color: $mercury;
        }
    }

    &__child{
        position: absolute;
        bottom: -100%;
        transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
        box-shadow: rgba(0, 0, 0, 0.08) 0 0 4px;
        left: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        background: $white;

        &::before {
            display: block;
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100%;
            background: $moreBlurBlack;
            z-index: -1;
            opacity: 0;
            transition: 1000ms cubic-bezier(0.77, 0, 0.175, 1);
            pointer-events: none;
        }

        &--on{
            bottom: 0;

            &::before {
                opacity: 1;
            }
        }

        span{
            width: 20px;
            height: 2px;
            margin-top: 10px;
            background-color: $mercury;
        }

        .fund-factsheet__menu{
            background: $white;
            padding: 30px 15px;
            min-height: 380px;

            .nw-card{
                box-shadow: unset;
                border: unset;
                padding: 0;
            }

            .past-performance__silder{
                padding: 20px;
                @include displayFlex;
                flex-direction: column;
                align-items: center;

                span{
                    color: $greenBlue;
                    background-color: transparent;
                }
            }

            .fund-multiplier{

                &__list{
                    @include displayFlex;
                    align-items: center;
                    justify-content: space-evenly;

                    &__item{
                        @include displayFlex;
                        flex-direction: column;
                        align-items: center;

                        .nw-button--filter{
                            background-color: $white;
                            width: 85px;
                            margin-top: -12px;
                            margin-right: 0px;
                        }
                    }
                }

                &__desc{
                    @include displayFlex;
                    align-items: center;
                    justify-content: center;
                    margin-top: 10px;

                    p{
                        margin: 0px;
                    }

                    h4{
                        color: $greenBlue;
                        background-color: transparent;
                        font-weight: $font-weight--base;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}