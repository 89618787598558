@import '../../styles/shared';

$color-slider: $offYellow !default;
$handle-size: 24px !default;
$border-size: 4px !default;

.rc-slider-track,
.rc-slider-rail {
  height: 2px;
}

.rc-slider-track,
.rc-slider-handle {
  background: $color-slider;
}

.rc-slider-handle {
  border: $border-size solid $alabaster;
  width: $handle-size;
  height: $handle-size;
  margin-top: #{-($handle-size - $border-size) / 2};
}
