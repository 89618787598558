@import '../../styles/shared';
$color--pending: $reddishOrange;
$color--inProcess: $offYellow;
$color--completed: $completeGreen;

.nw-transaction-item{
    font-size: $font-size--small;
    @include displayFlex;
    flex-direction: column;

    & > div {
      @include displayFlex;
      justify-content: space-between;
      margin-bottom: 10px;
    }

    h4{
        font-weight: $font-weight--bold;
        margin: 0;
    }

    &__status {
      font-style: italic;

      &--pending {
        color: $color--pending;
      }

      &--inProcess {
        color: $color--inProcess;
      }

      &--completed {
        color: $color--completed;
      }
    }

    &__times{
       @include displayFlex;
        align-items: center;

        img{
            margin-right: 5px;
        }

        &__grey{
            color: $grey;
        }
    }

    &__number{
      font-weight: $font-weight--base;
      // margin-bottom: 6px;
      text-align: right;
    }

    nav {
      a {
        margin-right: 5px;
      }

      svg {
        path {
          fill: $grey;
        }
      }
    }
}