@import '../../styles/shared';

$download--space: 14px;
$download--iconSize: 48px;
$download--linkColor: $grey;

.nw-downloads {
    @include displayFlex;
    width: 100%;

    &__item {
        display: block;
        color: $download--linkColor;
        padding: $download--space;
        width: 300px / 900px * 100%;
        font-size: $font-size--small;
        
        // @for $i from 1 through 2 {
        //     &:nth-child(#{$i}) {
        //         @if $i = 3 {
        //             // width: 50%;
        //             width: 300px / 900px * 100%;
        //         }
        //     }
        // }

        + .nw-downloads__item {
            border-left: 1px solid $color-border;
        }
        
        img {
            width: $download--iconSize;
            height: auto;
            display: block;
            margin-bottom: $download--space;
        }

        &:focus, &:visited {
            color: $download--linkColor;
        }
    }
}
