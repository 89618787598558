@import '../../styles/shared';

$marker-width: 65px !default;

.risk-scale {
  height: 140px;
  background: url('../../assets/images/scale.png') repeat-x bottom left / contain;
  margin-bottom: 30px;
  flex-shrink: 0;

  &__marker {
    position: relative;
    display: inline-block;
    transform: translateX(-50%);

    img {
      width: $marker-width;
      height: auto;
    }

    span {
      font-size: $font-size--bigger;
      font-weight: bold;
      position: absolute;
      left: 50%;
      top: 25px;
      transform: translateX(-50%);
    }
  }
}