@import '../../styles/shared';

$navbar--iconSize: 24px;
$color-text: $white !default;

.nw-top-nav {
  @include displayFlex;
  justify-content: space-between;
  align-items: center;
  min-height: $navbar--height;
  padding: 0 $common--space;
  color: $color-text;

  svg {
    min-width: $navbar--iconSize;
    min-height: $navbar--iconSize;

    path {
      fill: $white;
    }
  }

  img {
    width: $navbar--iconSize;
    height: auto;
  }
}