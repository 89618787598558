@import '../../styles/shared';

$icon-size: 148px;
$color-text: $greyishBrown;

.nw-page-orderReceived {
  @include displayFlex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90px $based-padding 60px $based-padding;
  text-align: center;
  height: 100vh;

  img {
    width: $icon-size;
    height: auto;
    margin-bottom: 90px;
  }

  h2 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: $font-size--title;
  }

  p {
    line-height: $line-height;
    color: $color-text;
    margin-top: 0;
    + span {
      flex-grow: 1;
    }
  }

  .nw-button {
    width: 100%;
  }
}