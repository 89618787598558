@import '../../styles/shared';


.nw-page-buyFund {
  
  .nw-page__body {
    font-size: $font-size--small;
  }

  &__note {
    font-size: $font-size--small;
    line-height: $line-height;
  }

  .nw-mutual-fund {
    overflow: visible;
  }
}