@import url(https://fonts.googleapis.com/css?family=Roboto:300,300i,400,400i,500,700&display=swap);
/* Document
   ========================================================================== */
/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in iOS.
 */
html {
  line-height: 1.15;
  /* 1 */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  box-sizing: border-box; }
  html * {
    box-sizing: border-box; }

/* Sections
     ========================================================================== */
/**
   * Remove the margin in all browsers.
   */
body {
  margin: 0; }

/**
   * Correct the font size and margin on `h1` elements within `section` and
   * `article` contexts in Chrome, Firefox, and Safari.
   */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
     ========================================================================== */
/**
   * 1. Add the correct box sizing in Firefox.
   * 2. Show the overflow in Edge and IE.
   */
hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics
     ========================================================================== */
/**
   * Remove the gray background on active links in IE 10.
   */
a {
  background-color: transparent; }

/**
   * 1. Remove the bottom border in Chrome 57-
   * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
   */
abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  /* 2 */ }

/**
   * Add the correct font weight in Chrome, Edge, and Safari.
   */
b,
strong {
  font-weight: bolder; }

/**
   * 1. Correct the inheritance and scaling of font size in all browsers.
   * 2. Correct the odd `em` font sizing in all browsers.
   */
code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
   * Add the correct font size in all browsers.
   */
small {
  font-size: 80%; }

/**
   * Prevent `sub` and `sup` elements from affecting the line height in
   * all browsers.
   */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sub {
  bottom: -0.25em; }

sup {
  top: -0.5em; }

/* Embedded content
     ========================================================================== */
/**
   * Remove the border on images inside links in IE 10.
   */
img {
  border-style: none; }

/* Forms
     ========================================================================== */
/**
   * 1. Change the font styles in all browsers.
   * 2. Remove the margin in Firefox and Safari.
   */
button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;
  /* 2 */ }

/**
   * Show the overflow in IE.
   * 1. Show the overflow in Edge.
   */
button,
input {
  /* 1 */
  overflow: visible; }

/**
   * Remove the inheritance of text transform in Edge, Firefox, and IE.
   * 1. Remove the inheritance of text transform in Firefox.
   */
button,
select {
  /* 1 */
  text-transform: none; }

/**
   * Correct the inability to style clickable types in iOS and Safari.
   */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/**
   * Remove the inner border and padding in Firefox.
   */
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
   * Restore the focus styles unset by the previous rule.
   */
button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText; }

/**
   * Correct the padding in Firefox.
   */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
   * 1. Correct the text wrapping in Edge and IE.
   * 2. Correct the color inheritance from `fieldset` elements in IE.
   * 3. Remove the padding so developers are not caught out when they zero out
   *    `fieldset` elements in all browsers.
   */
legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */ }

/**
   * Add the correct vertical alignment in Chrome, Firefox, and Opera.
   */
progress {
  vertical-align: baseline; }

/**
   * Remove the default vertical scrollbar in IE 10+.
   */
textarea {
  overflow: auto; }

/**
   * 1. Add the correct box sizing in IE 10.
   * 2. Remove the padding in IE 10.
   */
[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */ }

/**
   * Correct the cursor style of increment and decrement buttons in Chrome.
   */
[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
   * 1. Correct the odd appearance in Chrome and Safari.
   * 2. Correct the outline style in Safari.
   */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
   * Remove the inner padding in Chrome and Safari on macOS.
   */
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
   * 1. Correct the inability to style clickable types in iOS and Safari.
   * 2. Change font properties to `inherit` in Safari.
   */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/* Interactive
     ========================================================================== */
/*
   * Add the correct display in Edge, IE 10+, and Firefox.
   */
details {
  display: block; }

/*
   * Add the correct display in all browsers.
   */
summary {
  display: list-item; }

/* Misc
     ========================================================================== */
/**
   * Add the correct display in IE 10+.
   */
template {
  display: none; }

/**
   * Add the correct display in IE 10.
   */
[hidden] {
  display: none; }

body {
  font-family: -apple-system, BlinkMacSystemFont, "Roboto", arial, sans-serif; }

body {
  font-size: 16px; }

strong, b {
  font-weight: 700; }

a {
  text-decoration: none;
  color: #3485ff; }
  a:hover {
    color: #0052cd; }
  a:focus {
    color: #3485ff; }

section.transition-group {
  position: relative; }
  section.transition-group > div:first-child {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0; }

.forward-enter {
  -webkit-transform: translateX(100%);
          transform: translateX(100%); }
  .forward-enter.forward-enter-active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in; }
  .forward-enter + .backward-exit.backward-exit-active {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%); }

.forward-exit {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  .forward-exit.forward-exit-active {
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in; }

.backward-enter {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%); }
  .backward-enter.backward-enter-active {
    -webkit-transform: translateX(0);
            transform: translateX(0);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in; }
  .backward-enter + .forward-exit.forward-exit-active {
    -webkit-transform: translateX(100%);
            transform: translateX(100%); }

.backward-exit {
  -webkit-transform: translateX(0);
          transform: translateX(0); }
  .backward-exit.backward-exit-active {
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
    -webkit-transition: all 300ms ease-in;
    transition: all 300ms ease-in; }

.nw-display--flex {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */ }

.recharts-default-legend li svg {
  position: relative;
  top: -2px; }

.recharts-default-legend li + li {
  margin-left: 20px; }

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0; }

input[type=number] {
  -moz-appearance: textfield;
  /* Firefox */ }

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px); } }

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  40% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  60% {
    -webkit-transform: translateY(-15px);
    transform: translateY(-15px); } }

@-webkit-keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

@keyframes flash {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }

@-webkit-keyframes jello {
  11.1% {
    -webkit-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@keyframes jello {
  11.1% {
    -webkit-transform: none;
    transform: none; }
  22.2% {
    -webkit-transform: skewX(-12.5deg) skewY(-12.5deg);
    transform: skewX(-12.5deg) skewY(-12.5deg); }
  33.3% {
    -webkit-transform: skewX(6.25deg) skewY(6.25deg);
    transform: skewX(6.25deg) skewY(6.25deg); }
  44.4% {
    -webkit-transform: skewX(-3.125deg) skewY(-3.125deg);
    transform: skewX(-3.125deg) skewY(-3.125deg); }
  55.5% {
    -webkit-transform: skewX(1.5625deg) skewY(1.5625deg);
    transform: skewX(1.5625deg) skewY(1.5625deg); }
  66.6% {
    -webkit-transform: skewX(-0.78125deg) skewY(-0.78125deg);
    transform: skewX(-0.78125deg) skewY(-0.78125deg); }
  77.7% {
    -webkit-transform: skewX(0.39062deg) skewY(0.39062deg);
    transform: skewX(0.39062deg) skewY(0.39062deg); }
  88.8% {
    -webkit-transform: skewX(-0.19531deg) skewY(-0.19531deg);
    transform: skewX(-0.19531deg) skewY(-0.19531deg); }
  100% {
    -webkit-transform: none;
    transform: none; } }

@-webkit-keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@keyframes rubberBand {
  0% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); }
  30% {
    -webkit-transform: scale3d(1.25, 0.75, 1);
    transform: scale3d(1.25, 0.75, 1); }
  40% {
    -webkit-transform: scale3d(0.75, 1.25, 1);
    transform: scale3d(0.75, 1.25, 1); }
  50% {
    -webkit-transform: scale3d(1.15, 0.85, 1);
    transform: scale3d(1.15, 0.85, 1); }
  65% {
    -webkit-transform: scale3d(0.95, 1.05, 1);
    transform: scale3d(0.95, 1.05, 1); }
  75% {
    -webkit-transform: scale3d(1.05, 0.95, 1);
    transform: scale3d(1.05, 0.95, 1); }
  100% {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1); } }

@-webkit-keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); } }

@keyframes shake {
  0%, 100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  10%, 30%, 50%, 70%, 90% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  20%, 40%, 60%, 80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); } }

@-webkit-keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
    transform-origin: top center; }
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

@keyframes swing {
  20%, 40%, 60%, 80%, 100% {
    -webkit-transform-origin: top center;
    transform-origin: top center; }
  20% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg); }
  40% {
    -webkit-transform: rotate(-10deg);
    transform: rotate(-10deg); }
  60% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg); }
  80% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

@-webkit-keyframes tada {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0); } }

@keyframes tada {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  10%, 20% {
    -webkit-transform: scale(0.9) rotate(-3deg);
    transform: scale(0.9) rotate(-3deg); }
  30%, 50%, 70%, 90% {
    -webkit-transform: scale(1.1) rotate(3deg);
    transform: scale(1.1) rotate(3deg); }
  40%, 60%, 80% {
    -webkit-transform: scale(1.1) rotate(-3deg);
    transform: scale(1.1) rotate(-3deg); }
  100% {
    -webkit-transform: scale(1) rotate(0);
    transform: scale(1) rotate(0); } }

@-webkit-keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); } }

@keyframes wobble {
  0% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); }
  15% {
    -webkit-transform: translateX(-25%) rotate(-5deg);
    transform: translateX(-25%) rotate(-5deg); }
  30% {
    -webkit-transform: translateX(20%) rotate(3deg);
    transform: translateX(20%) rotate(3deg); }
  45% {
    -webkit-transform: translateX(-15%) rotate(-3deg);
    transform: translateX(-15%) rotate(-3deg); }
  60% {
    -webkit-transform: translateX(10%) rotate(2deg);
    transform: translateX(10%) rotate(2deg); }
  75% {
    -webkit-transform: translateX(-5%) rotate(-1deg);
    transform: translateX(-5%) rotate(-1deg); }
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%); } }

@-webkit-keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  70% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes bounceIn {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  70% {
    -webkit-transform: scale(0.9);
    transform: scale(0.9); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@-webkit-keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounceInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(30px);
    transform: translateY(30px); }
  80% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes bounceInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(30px);
    transform: translateX(30px); }
  80% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes bounceInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-30px);
    transform: translateX(-30px); }
  80% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes bounceInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  60% {
    opacity: 1;
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px); }
  80% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); } }

@keyframes bounceOut {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  25% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  50% {
    opacity: 1;
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.3);
    transform: scale(0.3); } }

@-webkit-keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@keyframes bounceOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-webkit-keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes bounceOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-webkit-keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes bounceOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-webkit-keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@keyframes bounceOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  20% {
    opacity: 1;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInDownBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInLeftBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes fadeInRightBig {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes fadeInUpBig {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

@-webkit-keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); } }

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px); } }

@-webkit-keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@keyframes fadeOutDownBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-webkit-keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); } }

@keyframes fadeOutLeft {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px); } }

@-webkit-keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes fadeOutLeftBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-webkit-keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); } }

@keyframes fadeOutRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(20px);
    transform: translateX(20px); } }

@-webkit-keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes fadeOutRightBig {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-webkit-keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); } }

@keyframes fadeOutUp {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px); } }

@-webkit-keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@keyframes fadeOutUpBig {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; } }

@keyframes flip {
  0% {
    -webkit-transform: perspective(400px) rotateY(0);
    transform: perspective(400px) rotateY(0);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  40% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(170deg);
    transform: perspective(400px) translateZ(150px) rotateY(170deg);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out; }
  50% {
    -webkit-transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    transform: perspective(400px) translateZ(150px) rotateY(190deg) scale(1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  80% {
    -webkit-transform: perspective(400px) rotateY(360deg) scale(0.95);
    transform: perspective(400px) rotateY(360deg) scale(0.95);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; }
  100% {
    -webkit-transform: perspective(400px) scale(1);
    transform: perspective(400px) scale(1);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in; } }

@-webkit-keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); } }

@keyframes flipInX {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateX(-10deg);
    transform: perspective(400px) rotateX(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateX(10deg);
    transform: perspective(400px) rotateX(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); } }

@-webkit-keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); } }

@keyframes flipInY {
  0% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); }
  40% {
    -webkit-transform: perspective(400px) rotateY(-10deg);
    transform: perspective(400px) rotateY(-10deg); }
  70% {
    -webkit-transform: perspective(400px) rotateY(10deg);
    transform: perspective(400px) rotateY(10deg); }
  100% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); } }

@-webkit-keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); } }

@keyframes flipOutX {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateX(0deg);
    transform: perspective(400px) rotateX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateX(90deg);
    transform: perspective(400px) rotateX(90deg); } }

@-webkit-keyframes flipOutY {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); } }

@keyframes flipOutY {
  0% {
    opacity: 1;
    -webkit-transform: perspective(400px) rotateY(0deg);
    transform: perspective(400px) rotateY(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: perspective(400px) rotateY(90deg);
    transform: perspective(400px) rotateY(90deg); } }

@-webkit-keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg); }
  80% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); } }

@keyframes lightSpeedIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); }
  60% {
    opacity: 1;
    -webkit-transform: translateX(-20%) skewX(30deg);
    transform: translateX(-20%) skewX(30deg); }
  80% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(-15deg);
    transform: translateX(0%) skewX(-15deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); } }

@-webkit-keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); } }

@keyframes lightSpeedOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%) skewX(0deg);
    transform: translateX(0%) skewX(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) skewX(-30deg);
    transform: translateX(100%) skewX(-30deg); } }

@-webkit-keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    -webkit-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center; } }

@keyframes rotateIn {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-200deg);
    transform: rotate(-200deg);
    -webkit-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center; } }

@-webkit-keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@keyframes rotateInDownLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-webkit-keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@keyframes rotateInDownRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-webkit-keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@keyframes rotateInUpLeft {
  0% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-webkit-keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@keyframes rotateInUpRight {
  0% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-webkit-keyframes rotateOut {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    -webkit-transform-origin: center center;
    transform-origin: center center; } }

@keyframes rotateOut {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: center center;
    transform-origin: center center; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(200deg);
    transform: rotate(200deg);
    -webkit-transform-origin: center center;
    transform-origin: center center; } }

@-webkit-keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@keyframes rotateOutDownLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-webkit-keyframes rotateOutDownRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@keyframes rotateOutDownRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-webkit-keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@keyframes rotateOutUpLeft {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
    -webkit-transform-origin: left bottom;
    transform-origin: left bottom; } }

@-webkit-keyframes rotateOutUpRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@keyframes rotateOutUpRight {
  0% {
    opacity: 1;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; }
  100% {
    opacity: 0;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
    -webkit-transform-origin: right bottom;
    transform-origin: right bottom; } }

@-webkit-keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes slideInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes slideInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@keyframes slideInRight {
  0% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0); } }

@-webkit-keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@keyframes slideInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0); } }

@-webkit-keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@keyframes slideOutDown {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(2000px);
    transform: translateY(2000px); } }

@-webkit-keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@keyframes slideOutLeft {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(-2000px);
    transform: translateX(-2000px); } }

@-webkit-keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@keyframes slideOutRight {
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(2000px);
    transform: translateX(2000px); } }

@-webkit-keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@keyframes slideOutUp {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0); }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-2000px);
    transform: translateY(-2000px); } }

@-webkit-keyframes hinge {
  0% {
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  20%, 60% {
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  40% {
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  80% {
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(700px);
    transform: translateY(700px); } }

@keyframes hinge {
  0% {
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(0);
    transform: rotate(0);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  20%, 60% {
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(80deg);
    transform: rotate(80deg);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  40% {
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg);
    transform: rotate(60deg);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  80% {
    opacity: 1;
    -webkit-animation-timing-function: ease-in-out;
            animation-timing-function: ease-in-out;
    -webkit-transform: rotate(60deg) translateY(0);
    transform: rotate(60deg) translateY(0);
    -webkit-transform-origin: top left;
    transform-origin: top left; }
  100% {
    opacity: 0;
    -webkit-transform: translateY(700px);
    transform: translateY(700px); } }

@-webkit-keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); } }

@keyframes rollIn {
  0% {
    opacity: 0;
    -webkit-transform: translateX(-100%) rotate(-120deg);
    transform: translateX(-100%) rotate(-120deg); }
  100% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); } }

@-webkit-keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg); } }

@keyframes rollOut {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0px) rotate(0deg);
    transform: translateX(0px) rotate(0deg); }
  100% {
    opacity: 0;
    -webkit-transform: translateX(100%) rotate(120deg);
    transform: translateX(100%) rotate(120deg); } }

@-webkit-keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@keyframes zoomIn {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  50% {
    opacity: 1; } }

@-webkit-keyframes zoomInDown {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); } }

@keyframes zoomInDown {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); } }

@-webkit-keyframes zoomInLeft {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0); } }

@keyframes zoomInLeft {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(-1000px, 0, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(10px, 0, 0); } }

@-webkit-keyframes zoomInRight {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0); } }

@keyframes zoomInRight {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(1000px, 0, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-10px, 0, 0); } }

@-webkit-keyframes zoomInUp {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); } }

@keyframes zoomInUp {
  0% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 1000px, 0); }
  60% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); } }

@-webkit-keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

@keyframes zoomOut {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3); }
  100% {
    opacity: 0; } }

@-webkit-keyframes zoomOutDown {
  40% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; } }

@keyframes zoomOutDown {
  40% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, -60px, 0); }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, 2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; } }

@-webkit-keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

@keyframes zoomOutLeft {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(-2000px, 0, 0);
    transform: scale(0.1) translate3d(-2000px, 0, 0);
    -webkit-transform-origin: left center;
    transform-origin: left center; } }

@-webkit-keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

@keyframes zoomOutRight {
  40% {
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(-42px, 0, 0); }
  100% {
    opacity: 0;
    -webkit-transform: scale(0.1) translate3d(2000px, 0, 0);
    transform: scale(0.1) translate3d(2000px, 0, 0);
    -webkit-transform-origin: right center;
    transform-origin: right center; } }

@-webkit-keyframes zoomOutUp {
  40% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; } }

@keyframes zoomOutUp {
  40% {
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
            animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    opacity: 1;
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0); }
  100% {
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
            animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    opacity: 0;
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -2000px, 0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; } }

.nw-button {
  display: block;
  min-height: 48px;
  background: inherit;
  color: inherit;
  border: 1px solid transparent;
  text-align: center;
  line-height: normal;
  line-height: initial;
  font-weight: 400;
  font-size: 16px;
  padding: 0 20px;
  border-radius: 48px;
  -o-border-radius: 48px; }
  .nw-button:focus, .nw-button:active {
    outline: none; }
  .nw-button--primary {
    background: #3485ff;
    border-color: #3485ff;
    color: #ffffff; }
    .nw-button--primary.nw-button--outline {
      background: transparent;
      color: #3485ff; }
    .nw-button--primary.nw-button--selected {
      position: relative; }
      .nw-button--primary.nw-button--selected:before {
        position: absolute;
        content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAgJJREFUSA21ls8rRUEUx78zyLNg7cfCwsLOQlHIHyBC2bFQilJKpMRGLNixsRBlx0ryirWFheIvUCzeAmVhY0HhjvO9z3jDnXt79+HW6905c+bzPfPOmTNPIeHpWDcNr88YVEAvFJqMQT3dlcIdDG4McFJRhaPzWXUbh5G10ad7w9S9PGNZZsYEWhb1KFhE7F1Gu5kqLJ3NqPvCTP4tItC+avoksn0BV/90ThqL0JPAhi8W1bHrp91B+5qZCgyyaeFkcA3XkuEyv3bAyOkgk99EXeci3wOtMGB3Egp8/uZXpUTuE+XPJTlpZk7CaJnQv4JTkKzPIoFiKb69ICfGxGrxRZpkY3WVZ9CoWee/gfe3ACv9USkyyZZ8yCEq8SF8tBPYPPUDyNY8of7pZKuFT+4BD08xvsLWspXw+Me4eM1FwWUl2bE13xWzr2LhNjIt2b6zA/d7qBVY6HEtQFo42Zpd8TsmP5o/BGprCiJp4aRIT7vWbLk+gVfpkXMHeZGtkXy1JCbUA5EdnGj2cx4KzzysSO4RKAH+Xl6JbNiL2lbNtmR83CdSqk2C3rlcVBNhFfGyEENcNafWIItMLgwF2PVkK8MyDlLTogsCsuzt9nUO2L+1xvQvRQIy7F1A7TAHbhD/emVSiOq8LJgk+Xiryw2IPvTlGjdy6xPZgZ3g91/8bfkAdMvNEMoyPGsAAAAASUVORK5CYII=);
        width: 32px;
        height: 32px;
        left: 10px;
        top: 11px;
        -webkit-animation-name: fadeIn;
        animation-name: fadeIn;
        -webkit-animation-iteration-count: 1s;
        animation-iteration-count: 1s;
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-delay: 0s;
        animation-delay: 0s;
        -webkit-animation-timing-function: ease;
        animation-timing-function: ease;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden; }
  .nw-button--secondary {
    background: #00c693;
    color: #ffffff;
    border-color: #00c693; }
    .nw-button--secondary.nw-button--outline {
      background: transparent;
      color: #00c693; }
  .nw-button--tertiary {
    background: #f45f32;
    color: #ffffff;
    border-color: #f45f32; }
    .nw-button--tertiary.nw-button--outline {
      background: transparent;
      color: #f45f32; }
  .nw-button--disabled {
    background: #efeff4;
    color: #afb9c5;
    border-color: #efeff4;
    cursor: not-allowed; }
  .nw-button--small {
    min-height: 32px;
    font-size: 14px;
    border-radius: 32px;
    -o-border-radius: 32px; }
  .nw-button--big {
    min-height: 54px;
    font-size: 20px;
    border-radius: 54px;
    -o-border-radius: 54px; }
  .nw-button--filter {
    border-radius: 32px;
    -o-border-radius: 32px;
    font-size: 14px;
    min-height: 32px;
    min-width: 55px;
    background: transparent;
    color: #454545;
    border-color: #e5e5ea;
    padding: 0px; }
    @media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
      .nw-button--filter:first-child {
        margin-left: 4px; }
      .nw-button--filter:last-child {
        margin-right: 4px; } }
    .nw-button--filter--active {
      color: #ffffff;
      background-color: #000000 !important; }
  .nw-button--fullwidth {
    width: 100%; }

.nw-subTitle {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  align-items: center;
  justify-content: space-between;
  min-height: 48px; }
  .nw-subTitle--noTopSpace {
    margin-top: -18px; }
  .nw-subTitle strong {
    font-weight: 700; }
  .nw-subTitle a {
    color: #3485ff;
    text-decoration: none; }
    .nw-subTitle a:hover, .nw-subTitle a:focus {
      color: #0052cd; }
    .nw-subTitle a svg {
      margin-left: 5px; }
      .nw-subTitle a svg path {
        fill: #bebebe; }

.nw-item {
  min-height: 48px;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  font-size: 14px; }
  .nw-item > div {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    justify-content: space-between;
    align-items: center;
    color: #000000;
    width: 100%; }
  .nw-item--withNotes {
    flex-direction: column;
    padding: 14px 0; }
    .nw-item--withNotes small {
      margin-top: 5px;
      color: #999999; }
      .nw-item--withNotes small strong {
        color: #000000; }
      .nw-item--withNotes small strong + span {
        margin-left: 5px; }
      .nw-item--withNotes small span + span {
        margin-left: 5px; }
      .nw-item--withNotes small .stat {
        color: #00c693; }
  .nw-item--colored {
    position: relative; }
    .nw-item--colored i {
      position: absolute;
      bottom: -21px;
      width: 40px;
      height: 5px; }
  .nw-item__value {
    font-weight: 500; }
  .nw-item + .nw-item {
    border-top: 1px solid #f3f3f3; }
  .nw-item:focus, .nw-item:visited {
    color: #000000;
    outline: none; }
  .nw-item > span:last-child {
    font-weight: 500; }
  .nw-item span.date {
    color: #999999; }

.nw-card > .nw-card__body > .nw-item:first-child {
  min-height: 0;
  padding-bottom: 16px; }

.nw-card .nw-card__header > .nw-item {
  min-height: 0; }
  .nw-card .nw-card__header > .nw-item span:first-child {
    font-weight: 700; }

.nw-card__header-withIcon {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */ }
  .nw-card__header-withIcon-icon {
    width: 50px;
    height: 50px;
    margin-right: 15px; }
    .nw-card__header-withIcon-icon img {
      width: 100%;
      height: auto; }
  .nw-card__header-withIcon-title {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 5px; }
  .nw-card__header-withIcon-subTitle {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px; }
  .nw-card__header-withIcon-desc {
    color: #00c693; }
    .nw-card__header-withIcon-desc span {
      margin-left: 5px; }
  .nw-card__header-withIcon-button svg {
    min-width: 20px;
    height: 20px; }
    .nw-card__header-withIcon-button svg path {
      fill: #bebebe; }
  .nw-card__header-withIcon-button img {
    width: 26px;
    height: auto; }

.nw-card__header-text {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  justify-content: space-between;
  flex: 1 1;
  margin-top: 5px; }

.nw-card {
  padding: 20px;
  background: #ffffff;
  border-radius: 0 0 12px 12px;
  -o-border-radius: 0 0 12px 12px;
  box-shadow: 0 4px 12px -12px rgba(38, 46, 63, 0.3);
  border-top: 1px solid rgba(84, 84, 84, 0.6); }
  .nw-card--none-padding-side {
    padding: 20px 0px; }
  .nw-card + .nw-card {
    margin-top: 20px; }
  .nw-card__header {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-size: 14px; }
    .nw-card__header .nw-subTitle {
      min-height: 0; }
      .nw-card__header .nw-subTitle strong {
        font-weight: 500; }
    .nw-card__header--flex {
      /* OLD - iOS 6-, Safari 3.1-6 */
      /* OLD - Firefox 19- (buggy but mostly works) */
      /* TWEENER - IE 10 */
      /* NEW - Chrome */
      display: flex;
      /* NEW, Spec - Opera 12.1, Firefox 20+ */
      justify-content: space-between; }
  .nw-card + .nw-subTitle {
    margin-top: 20px; }

.nw-itemList {
  width: 100%;
  margin: 0;
  font-size: 14px; }

.nw-card .nw-card__body > .nw-itemList {
  margin-top: -18px; }

.nw-tooltip {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 12px;
  -o-border-radius: 12px;
  box-shadow: 0px 4pt 18pt -12pt; }
  .nw-tooltip p {
    margin: 0px; }
  .nw-tooltip header {
    border-bottom: 1px solid #e5e5ea;
    padding-bottom: 15px; }
    .nw-tooltip header p {
      color: #454545; }
  .nw-tooltip section {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    align-items: center;
    margin-top: 15px; }
    .nw-tooltip section p {
      font-weight: 400;
      margin-bottom: 0;
      color: #00c693; }

.nw-mutual-fund {
  background-color: #ffffff;
  border-radius: 12px;
  -o-border-radius: 12px;
  overflow: hidden; }
  .nw-mutual-fund:not(:first-child) {
    margin-top: 20px; }
  .nw-mutual-fund:not(:last-child) {
    margin-bottom: 20px; }
  .nw-mutual-fund__header {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #404040;
    height: 140px;
    width: 100%; }
    .nw-mutual-fund__header__group {
      /* OLD - iOS 6-, Safari 3.1-6 */
      /* OLD - Firefox 19- (buggy but mostly works) */
      /* TWEENER - IE 10 */
      /* NEW - Chrome */
      display: flex;
      /* NEW, Spec - Opera 12.1, Firefox 20+ */
      width: 100%;
      align-items: center;
      justify-content: space-between;
      padding-top: 30px; }
      .nw-mutual-fund__header__group__status {
        /* OLD - iOS 6-, Safari 3.1-6 */
        /* OLD - Firefox 19- (buggy but mostly works) */
        /* TWEENER - IE 10 */
        /* NEW - Chrome */
        display: flex;
        /* NEW, Spec - Opera 12.1, Firefox 20+ */
        align-items: center;
        justify-content: space-between;
        min-height: 32px;
        background-color: #ffffff;
        border-radius: 0px 22px 22px 0px;
        -o-border-radius: 0px 22px 22px 0px;
        padding-left: 16px;
        padding-right: 42px;
        position: relative; }
        .nw-mutual-fund__header__group__status img {
          position: absolute;
          right: -2px;
          width: 32px;
          height: 32px; }
      .nw-mutual-fund__header__group__status-btn {
        position: relative; }
        .nw-mutual-fund__header__group__status-btn .nw-button {
          position: relative;
          right: 20px;
          margin-top: 0px;
          font-size: 16px; }
        .nw-mutual-fund__header__group__status-btn__closed {
          /* OLD - iOS 6-, Safari 3.1-6 */
          /* OLD - Firefox 19- (buggy but mostly works) */
          /* TWEENER - IE 10 */
          /* NEW - Chrome */
          display: flex;
          /* NEW, Spec - Opera 12.1, Firefox 20+ */
          align-items: center;
          justify-content: center;
          top: 42px;
          border-radius: 22px 0px 0px 22px;
          -o-border-radius: 22px 0px 0px 22px;
          background-color: rgba(0, 0, 0, 0.8);
          color: #ffffff;
          font-size: 14px;
          position: relative;
          padding: 0px 10px 0 20px; }
          .nw-mutual-fund__header__group__status-btn__closed img {
            position: absolute;
            top: 22px;
            left: -10px;
            color: #000000;
            max-width: 50px; }
          .nw-mutual-fund__header__group__status-btn__closed p {
            margin: 10px 0px;
            font-size: 14px; }
  .nw-mutual-fund section {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: column;
    padding: 24px 20px; }
    .nw-mutual-fund section .nw-mutual-fund__navigate {
      justify-content: space-between;
      /* OLD - iOS 6-, Safari 3.1-6 */
      /* OLD - Firefox 19- (buggy but mostly works) */
      /* TWEENER - IE 10 */
      /* NEW - Chrome */
      display: flex;
      /* NEW, Spec - Opera 12.1, Firefox 20+ */
      align-items: center; }
      .nw-mutual-fund section .nw-mutual-fund__navigate img {
        max-width: 28px; }
    .nw-mutual-fund section h3 {
      text-align: left;
      margin: 18px 0px;
      font-size: 18px; }
    .nw-mutual-fund section h4 {
      margin: 0px;
      font-size: 16px;
      text-align: left;
      color: #545454;
      font-weight: 400; }
    .nw-mutual-fund section ul {
      /* OLD - iOS 6-, Safari 3.1-6 */
      /* OLD - Firefox 19- (buggy but mostly works) */
      /* TWEENER - IE 10 */
      /* NEW - Chrome */
      display: flex;
      /* NEW, Spec - Opera 12.1, Firefox 20+ */
      justify-content: space-between;
      padding: 0;
      margin-bottom: 0;
      list-style-type: none; }
      .nw-mutual-fund section ul li {
        /* OLD - iOS 6-, Safari 3.1-6 */
        /* OLD - Firefox 19- (buggy but mostly works) */
        /* TWEENER - IE 10 */
        /* NEW - Chrome */
        display: flex;
        /* NEW, Spec - Opera 12.1, Firefox 20+ */
        flex-direction: column;
        font-size: 16px; }
        .nw-mutual-fund section ul li p {
          margin: 0px 10px 10px 0px;
          font-weight: 700; }
        .nw-mutual-fund section ul li .nw-mutual-fund__analytics {
          /* OLD - iOS 6-, Safari 3.1-6 */
          /* OLD - Firefox 19- (buggy but mostly works) */
          /* TWEENER - IE 10 */
          /* NEW - Chrome */
          display: flex;
          /* NEW, Spec - Opera 12.1, Firefox 20+ */
          justify-content: flex-start;
          align-items: center; }
          .nw-mutual-fund section ul li .nw-mutual-fund__analytics img {
            max-width: 16px;
            max-height: 16px;
            margin-right: 5px; }
          .nw-mutual-fund section ul li .nw-mutual-fund__analytics h4 {
            font-weight: 400;
            font-size: 14px;
            color: #545454; }

.tabs-menu > div {
  padding: 20px 15px; }

.tabs-menu--open {
  bottom: 0; }
  .tabs-menu--open::before {
    opacity: 1; }

.tabs-menu__tabs {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  align-items: center;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding-left: 0;
  margin-top: 0;
  margin-bottom: 20px;
  -webkit-transition: -webkit-transform 0.5s ease-out;
  transition: -webkit-transform 0.5s ease-out;
  transition: transform 0.5s ease-out;
  transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out; }
  .tabs-menu__tabs li {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    min-height: 32px;
    align-items: center;
    list-style: none;
    padding: 0 15px;
    color: #3485ff;
    border-radius: 26px;
    -o-border-radius: 26px;
    cursor: pointer;
    border: 1px solid transparent;
    -webkit-tap-highlight-color: transparent; }
    .tabs-menu__tabs li.tabs-menu--active {
      border: 1px solid #3485ff; }
    .tabs-menu__tabs li:not(:last-child) {
      margin-right: 10px; }

.tabs-menu__content > div {
  display: none; }

.tabs-menu__content--active {
  display: block !important;
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1;
  -webkit-animation-timing-function: ease-in;
          animation-timing-function: ease-in;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s; }

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

@keyframes fadeIn {
  0% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.nw-percent-box {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  flex: 1 1; }
  .nw-percent-box:not(:first-child) {
    padding-left: 20px; }
  .nw-percent-box:not(:last-child) {
    padding-right: 20px;
    border-right: 1px solid rgba(0, 0, 0, 0.1); }
  .nw-percent-box__donut {
    margin-bottom: 12px;
    width: 26px;
    height: 26px; }
  .nw-percent-box__text {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: column;
    justify-content: space-between;
    flex: 1 1; }
  .nw-percent-box__title {
    margin-bottom: 12px;
    color: #999999; }
  .nw-percent-box__percent {
    font-weight: 700;
    font-size: 16px; }

.nw-top-nav {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  justify-content: space-between;
  align-items: center;
  min-height: 64px;
  padding: 0 20px;
  color: #ffffff; }
  .nw-top-nav svg {
    min-width: 24px;
    min-height: 24px; }
    .nw-top-nav svg path {
      fill: #ffffff; }
  .nw-top-nav img {
    width: 24px;
    height: auto; }

.nw-downloads {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  width: 100%; }
  .nw-downloads__item {
    display: block;
    color: #999999;
    padding: 14px;
    width: 33.33333%;
    font-size: 14px; }
    .nw-downloads__item + .nw-downloads__item {
      border-left: 1px solid #e5e5ea; }
    .nw-downloads__item img {
      width: 48px;
      height: auto;
      display: block;
      margin-bottom: 14px; }
    .nw-downloads__item:focus, .nw-downloads__item:visited {
      color: #999999; }

.nw-page {
  height: 100vh; }
  .nw-page__header {
    height: 64px;
    background-color: #3485ff;
    background-image: url(/static/media/navbar.7eb43ca0.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom; }
  .nw-page--transparent-header .nw-page__header {
    background-image: none; }
  .nw-page__body {
    height: calc(100vh - 64px);
    max-height: calc(100vh - 64px);
    background: #f7f7f7;
    overflow-y: auto;
    padding: 20px 20px 60px;
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: column;
    scroll-behavior: smooth; }

.nw-transaction-item {
  font-size: 14px;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: column; }
  .nw-transaction-item > div {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    justify-content: space-between;
    margin-bottom: 10px; }
  .nw-transaction-item h4 {
    font-weight: 700;
    margin: 0; }
  .nw-transaction-item__status {
    font-style: italic; }
    .nw-transaction-item__status--pending {
      color: #f45f32; }
    .nw-transaction-item__status--inProcess {
      color: #fcd53b; }
    .nw-transaction-item__status--completed {
      color: #1AC735; }
  .nw-transaction-item__times {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    align-items: center; }
    .nw-transaction-item__times img {
      margin-right: 5px; }
    .nw-transaction-item__times__grey {
      color: #999999; }
  .nw-transaction-item__number {
    font-weight: 400;
    text-align: right; }
  .nw-transaction-item nav a {
    margin-right: 5px; }
  .nw-transaction-item nav svg path {
    fill: #999999; }

.nw-transaction {
  width: 100%; }
  .nw-transaction .nw-transaction__list:not(:first-child) {
    padding-top: 17px; }
  .nw-transaction .nw-transaction__list:not(:last-child) {
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 17px; }

.investment-analysis {
  width: 100%;
  font-size: 14px; }

.gauge-item {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  justify-content: space-between;
  align-items: center;
  min-height: 48px; }
  .gauge-item span {
    display: block; }
  .gauge-item__value {
    min-width: 50%;
    height: 6px;
    background: #dde3e9;
    border-radius: 6px;
    -o-border-radius: 6px;
    overflow: hidden; }
    .gauge-item__value span {
      background: #00c693;
      height: 6px; }
  .gauge-item + .gauge-item {
    border-top: 1px solid #f3f3f3; }

.nw-item + .gauge-item {
  border-top: 1px solid #f3f3f3; }

.nw-card .nw-card__body > .investment-analysis {
  margin-top: -18px; }

.nw-menu {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  -webkit-transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
  opacity: 0;
  pointer-events: none;
  z-index: 1; }
  .nw-menu__container {
    left: -100%;
    position: absolute;
    min-height: 100vh;
    width: 280px;
    box-shadow: 4px 0 20px rgba(21, 24, 30, 0.1);
    background: #ffffff;
    color: #404859;
    -webkit-transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: 500ms cubic-bezier(0.77, 0, 0.175, 1); }
  .nw-menu svg path {
    fill: #404859; }
  .nw-menu h2 {
    font-size: 16px; }
  .nw-menu--open {
    opacity: 1;
    pointer-events: auto;
    background: rgba(0, 0, 0, 0.4); }
    .nw-menu--open .nw-menu__container {
      left: 0; }
  .nw-menu__top {
    padding: 40px;
    background: #eaeaea;
    text-align: center;
    border-bottom: 1px solid #f3f3f3; }
  .nw-menu__name {
    margin-bottom: 10px;
    font-weight: bold; }
  .nw-menu__date {
    font-size: 12px; }
  .nw-menu__bottom ul {
    padding-left: 0;
    margin: 0 0 0 20px; }
    .nw-menu__bottom ul li {
      list-style: none;
      padding-right: 20px;
      border-bottom: 1px solid #f3f3f3;
      height: 60px;
      /* OLD - iOS 6-, Safari 3.1-6 */
      /* OLD - Firefox 19- (buggy but mostly works) */
      /* TWEENER - IE 10 */
      /* NEW - Chrome */
      display: flex;
      /* NEW, Spec - Opera 12.1, Firefox 20+ */ }
      .nw-menu__bottom ul li a {
        /* OLD - iOS 6-, Safari 3.1-6 */
        /* OLD - Firefox 19- (buggy but mostly works) */
        /* TWEENER - IE 10 */
        /* NEW - Chrome */
        display: flex;
        /* NEW, Spec - Opera 12.1, Firefox 20+ */
        align-items: center;
        flex-grow: 1;
        color: #404859;
        text-transform: capitalize; }
  .nw-menu__bottom svg {
    margin-right: 10px; }
  .nw-menu__item--current {
    font-weight: bold; }
  .nw-menu__item--flex {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    justify-content: space-between;
    align-items: center;
    width: 100%; }
  .nw-menu__language {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    justify-content: space-between;
    align-items: center;
    background: #3485ff;
    border-radius: 16px;
    -o-border-radius: 16px;
    padding: 2px; }
    .nw-menu__language span {
      background: #3485ff;
      padding: 5px 0;
      color: #ffffff;
      border-radius: 16px;
      -o-border-radius: 16px;
      font-size: 14px;
      width: 34px;
      text-align: center; }
      .nw-menu__language span + span {
        margin-left: 3px; }
      .nw-menu__language span.nw-menu__language--active {
        background: #ffffff;
        color: #3485ff; }

.donut-chart-list {
  width: 100%;
  height: auto;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  justify-content: space-between;
  flex-direction: row; }
  .donut-chart-list--list-below {
    flex-direction: column; }
    .donut-chart-list--list-below .donut-chart-list__items {
      margin-top: 10px; }
  .donut-chart-list__chart {
    position: relative;
    min-width: 30%;
    max-width: 30%;
    height: 100px;
    margin-right: 20px;
    padding: 0 5px; }
  .donut-chart-list__items {
    flex-grow: 1;
    margin: 0;
    padding: 0;
    list-style: none; }
    .donut-chart-list__items li {
      position: relative; }
      .donut-chart-list__items li > div {
        /* OLD - iOS 6-, Safari 3.1-6 */
        /* OLD - Firefox 19- (buggy but mostly works) */
        /* TWEENER - IE 10 */
        /* NEW - Chrome */
        display: flex;
        /* NEW, Spec - Opera 12.1, Firefox 20+ */
        justify-content: space-between;
        padding: 10px 0; }
        .donut-chart-list__items li > div + .donut-chart-list__items__identifier {
          position: absoltue;
          display: block;
          width: 40px;
          height: 3px;
          border-radius: 3px;
          -o-border-radius: 3px; }
      .donut-chart-list__items li + li {
        margin-top: 10px; }

.nw-line-chart-filter {
  width: 100%;
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: column;
  align-items: center; }
  .nw-line-chart-filter__filter-area {
    width: 100%;
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    overflow-x: scroll;
    padding: 0px 5px; }
    .nw-line-chart-filter__filter-area__btn:not(:last-child) {
      margin-right: 10px; }
    .nw-line-chart-filter__filter-area__btn .nw-button {
      margin-top: 0; }
    @media only screen and (min-width: 813px) {
      .nw-line-chart-filter__filter-area {
        justify-content: space-around; } }
  .nw-line-chart-filter__chart-area {
    width: 100%;
    height: 180px;
    padding: 20px 20px 0px 20px; }
    .nw-line-chart-filter__chart-area > .nw-spinner {
      height: 160px; }

.action-modal {
  position: fixed;
  z-index: 999;
  display: block;
  opacity: 0;
  width: 100%;
  height: 100vh;
  padding: 10px;
  top: 0;
  left: 0;
  -webkit-transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
  pointer-events: none; }
  .action-modal--open {
    background: rgba(0, 0, 0, 0.4);
    opacity: 1;
    pointer-events: auto; }
    .action-modal--open .action-modal__body {
      bottom: 10px; }
  .action-modal__body {
    -webkit-transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
    margin-top: auto;
    text-align: center;
    width: calc(100% - 20px);
    position: absolute;
    left: 10px;
    bottom: -100%; }
  .action-modal__top, .action-modal__cancel {
    background: #ffffff;
    border-radius: 13px;
    -o-border-radius: 13px;
    width: 100%; }
  .action-modal__top {
    margin-bottom: 10px; }
  .action-modal__title {
    font-size: 14px;
    color: #999999;
    padding: 15px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .action-modal__content a, .action-modal__content span {
    color: #007aff; }
  .action-modal__content a {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    align-items: center;
    justify-content: center;
    min-height: 54px; }
  .action-modal__cancel {
    font-weight: bold;
    color: #007aff;
    padding: 15px;
    border: none;
    outline: none;
    min-height: 54px; }

.question-box .nw-card__body {
  flex-direction: column;
  font-size: 14px; }

.question-box__category {
  color: #3485ff;
  margin-bottom: 10px; }

.question-box h2 {
  margin: 0;
  font-size: 14px;
  line-height: 24px; }

.question-box__answers {
  line-height: 20px; }
  .question-box__answers .nw-option-box {
    justify-content: flex-end;
    align-items: flex-start; }
    .question-box__answers .nw-option-box:last-child {
      border-bottom: none;
      padding-bottom: 0; }

.nw-option-box {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  border-bottom: 1px solid #f3f3f3;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
  color: #404859;
  -webkit-tap-highlight-color: transparent; }
  .nw-option-box input {
    display: none; }
    .nw-option-box input[type=radio]:checked + span + span path {
      fill: #3485ff; }
  .nw-option-box__text {
    text-transform: capitalize; }
  .nw-option-box__checkmark--checked svg {
    min-width: 24px;
    height: 24px; }
    .nw-option-box__checkmark--checked svg path {
      fill: #3485ff; }
  .nw-option-box__checkmark svg {
    min-width: 24px;
    height: 24px; }
    .nw-option-box__checkmark svg path {
      fill: #E7E7E7; }
  .nw-option-box--left {
    flex-direction: row-reverse; }
    .nw-option-box--left input + span {
      margin-left: 20px; }

.select-box {
  position: relative;
  display: block;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .select-box h3 {
    font-size: 14px;
    color: rgba(38, 46, 63, 0.7);
    margin: 18px 0 5px;
    font-weight: normal; }
  .select-box--focused {
    border-color: #f45f32; }
  .select-box__current {
    position: relative;
    cursor: pointer;
    outline: none; }
  .select-box__icon {
    position: absolute;
    top: 50%;
    right: 0px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: 20px;
    opacity: 0.3;
    -webkit-transition: 0.2s ease;
    transition: 0.2s ease; }
  .select-box .select-box__list {
    max-height: 200px;
    overflow-y: scroll; }
    .select-box .select-box__list--active {
      opacity: 1;
      -webkit-animation-name: none;
              animation-name: none; }
  .select-box .select-box__option {
    cursor: pointer; }
  .select-box .select-box__icon--active {
    -webkit-transform: translateY(-50%) rotate(180deg);
            transform: translateY(-50%) rotate(180deg); }
  .select-box__value {
    display: flex; }
  .select-box__input {
    display: none; }
    .select-box__input:checked + .select-box__input-text {
      display: block; }
  .select-box__input-text {
    display: none;
    width: 100%;
    margin: 0;
    padding: 15px 0px;
    background-color: #ffffff;
    border: none;
    line-height: 1; }
    .select-box__input-text:focus {
      outline: none;
      color: #f45f32; }
  .select-box__list {
    position: absolute;
    width: 100%;
    padding: 0;
    list-style: none;
    opacity: 0;
    margin: 0;
    background: #ffffff;
    z-index: 1;
    -webkit-animation-name: HideList;
            animation-name: HideList;
    -webkit-animation-duration: 0.5s;
            animation-duration: 0.5s;
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
    -webkit-animation-fill-mode: forwards;
            animation-fill-mode: forwards;
    -webkit-animation-timing-function: step-start;
            animation-timing-function: step-start;
    box-shadow: 0 4px 12px -12px rgba(38, 46, 63, 0.3); }
  .select-box__option {
    display: block;
    padding: 15px;
    background-color: #ffffff; }
    .select-box__option:hover, .select-box__option:focus {
      background-color: #FBFBFB; }
    .select-box__option--active {
      background: #3485ff;
      color: #ffffff; }
    .select-box__option--disabled {
      background: #efeff4;
      color: #afb9c5; }

@-webkit-keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
            transform: scaleY(1); }
  to {
    -webkit-transform: scaleY(0);
            transform: scaleY(0); } }

@keyframes HideList {
  from {
    -webkit-transform: scaleY(1);
            transform: scaleY(1); }
  to {
    -webkit-transform: scaleY(0);
            transform: scaleY(0); } }

.filter-box {
  background: #ffffff;
  padding-left: 20px; }
  .filter-box h3 {
    padding: 0 20px;
    min-height: 42px;
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    align-items: center;
    margin: 0 0 0 -20px;
    color: #404859;
    background: #FBFBFB;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: normal;
    border-top: 1px solid #E6E6E6;
    border-bottom: 1px solid #E6E6E6; }
  .filter-box .nw-option-box {
    padding-right: 20px;
    padding-top: 0;
    padding-bottom: 0;
    min-height: 54px; }
  .filter-box__options {
    font-size: 14px; }
    .filter-box__options--horizontal {
      padding-top: 20px;
      padding-bottom: 20px;
      white-space: nowrap;
      overflow-x: auto; }
      .filter-box__options--horizontal span {
        display: inline-block;
        padding: 6px 19px;
        color: #3485ff;
        border: 1px solid #3485ff;
        border-radius: 24px;
        -o-border-radius: 24px; }
        .filter-box__options--horizontal span + span {
          margin-left: 10px; }
        .filter-box__options--horizontal span.filter-box__option--active {
          color: #ffffff;
          background: #3485ff; }

.fund-summary {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: column;
  width: 100%; }
  .fund-summary__header {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px; }
    .fund-summary__header__image {
      background-size: cover;
      background-color: #404859;
      width: 55px;
      height: 55px;
      border-radius: 50%;
      -o-border-radius: 50%;
      margin-right: 20px; }
    .fund-summary__header__fund {
      flex: 1 1; }
      .fund-summary__header__fund p {
        margin: 0px 0px 8px;
        color: #999999; }
      .fund-summary__header__fund h4 {
        margin: 9px 0px 0px;
        font-size: 14px; }
  .fund-summary__description {
    border-top: 1px solid #e5e5ea; }
    .fund-summary__description p {
      margin: 20px 0px; }
  .fund-summary__value ul {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    justify-content: space-between;
    border-top: 1px solid #e5e5ea;
    padding-top: 20px;
    list-style-type: none;
    -webkit-padding-start: 0px;
            padding-inline-start: 0px;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0; }
    .fund-summary__value ul:not(:last-child) {
      padding-bottom: 20px; }
    .fund-summary__value ul p {
      margin: 0px 0px 5px;
      color: #454545; }
    .fund-summary__value ul li {
      width: 50%; }
      .fund-summary__value ul li .fund-summary__value__detail {
        /* OLD - iOS 6-, Safari 3.1-6 */
        /* OLD - Firefox 19- (buggy but mostly works) */
        /* TWEENER - IE 10 */
        /* NEW - Chrome */
        display: flex;
        /* NEW, Spec - Opera 12.1, Firefox 20+ */
        align-items: center; }
        .fund-summary__value ul li .fund-summary__value__detail h4 {
          margin: 0px;
          text-indent: 10px; }
      .fund-summary__value ul li:last-child {
        padding-left: 20px; }

.nw-input-group {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  justify-content: center; }
  .nw-input-group input {
    width: 46px;
    height: 48px;
    border: 1px solid #f3f3f3;
    text-align: center;
    font-weight: bold; }
    .nw-input-group input + input {
      margin-left: 10px; }

.nw-input {
  padding: 0 20px;
  min-height: 48px;
  color: #000000;
  outline: none;
  border-radius: 24px;
  -o-border-radius: 24px;
  background: #f7f7f7;
  border: 1px solid #E7E7E7;
  width: 100%; }
  .nw-input--lined {
    background: none;
    border: none;
    border-radius: 0;
    -o-border-radius: 0;
    border-bottom: 0.5px solid rgba(255, 255, 255, 0.7);
    color: #ffffff;
    padding: 15px 0;
    letter-spacing: 0.5px; }
  .nw-input--invalid {
    border-color: #db3b21; }

.rc-slider-track,
.rc-slider-rail {
  height: 2px; }

.rc-slider-track,
.rc-slider-handle {
  background: #fcd53b; }

.rc-slider-handle {
  border: 4px solid #FBFBFB;
  width: 24px;
  height: 24px;
  margin-top: -10px; }

.nw-icon-button {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .nw-icon-button__icon {
    width: 96px;
    height: 96px;
    margin-bottom: 10px; }
    .nw-icon-button__icon img {
      width: 100%;
      height: auto; }
    .nw-icon-button__icon--active {
      display: none; }
  .nw-icon-button--active .nw-icon-button__icon {
    display: none; }
    .nw-icon-button--active .nw-icon-button__icon--active {
      display: block; }
  .nw-icon-button span {
    display: inline-block;
    padding: 6px 20px;
    border: none;
    border-top: 1px solid #f3f3f3;
    background: #ffffff;
    z-index: 1;
    margin-top: -25px; }

.nw-accordion {
  padding: 20px;
  background: #ffffff;
  border-radius: 0 0 12px 12px;
  -o-border-radius: 0 0 12px 12px;
  box-shadow: 0 4px 12px -12px rgba(38, 46, 63, 0.3);
  border-top: 1px solid rgba(84, 84, 84, 0.6);
  font-size: 14px; }
  .nw-accordion + .nw-accordion {
    margin-top: 20px; }
  .nw-accordion__top {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px; }
  .nw-accordion__category {
    color: #3485ff; }
  .nw-accordion__title {
    line-height: 24px;
    margin-bottom: 15px;
    font-weight: bold; }
  .nw-accordion__desc {
    max-height: 0;
    -webkit-transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
    line-height: 20px;
    overflow: hidden; }
  .nw-accordion--open .nw-accordion__desc {
    max-height: 300px; }
  .nw-accordion--open svg {
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg); }
  .nw-accordion svg {
    -webkit-transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: 500ms cubic-bezier(0.77, 0, 0.175, 1); }
    .nw-accordion svg path {
      fill: #bebebe; }

.nw-progress-bar {
  font-size: 14px; }
  .nw-progress-bar__percent {
    color: #3485ff;
    margin-bottom: 20px; }
  .nw-progress-bar__track {
    width: 100%;
    height: 4px;
    background: #FAFAFA;
    position: relative;
    margin-bottom: 20px; }
  .nw-progress-bar__progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: #3485ff; }
  .nw-progress-bar__description--warning {
    color: #f45f32; }

.nw-input-box {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }
  .nw-input-box--invalid {
    border-color: #db3b21; }
  .nw-input-box--focused {
    border-color: #f45f32;
    color: #f45f32; }
    .nw-input-box--focused input {
      color: #f45f32; }
  .nw-input-box h3, .nw-input-box h3 span {
    font-size: 14px;
    color: rgba(38, 46, 63, 0.7);
    margin: 18px 0 5px;
    font-weight: normal; }
  .nw-input-box > div {
    position: relative; }
  .nw-input-box input {
    border: none;
    padding: 15px 0;
    width: 100%;
    font-size: 16px; }
    .nw-input-box input:focus {
      outline: none; }
    .nw-input-box input:disabled {
      background: none;
      color: rgba(38, 46, 63, 0.7); }
  .nw-input-box--currency {
    position: relative;
    font-size: 16px; }
    .nw-input-box--currency span {
      position: absolute;
      left: 0;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); }
    .nw-input-box--currency.nw-input-box--disabled {
      color: rgba(38, 46, 63, 0.7); }
    .nw-input-box--currency input {
      padding-left: 30px; }
  .nw-input-box__input {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    justify-content: space-between;
    align-items: center; }
    .nw-input-box__input--card {
      position: relative; }
      .nw-input-box__input--card input {
        background: transparent; }
      .nw-input-box__input--card::after {
        position: absolute;
        content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACYAAAANCAYAAADFVhxbAAAAAXNSR0IArs4c6QAABQZJREFUOBFtVWtsVEUYPXNn7t1dwEILiLRFoCGgkhiRSINBRDGpPEskRWgNEaP4B6Imxn+aqsQ/xsTwA4KRkFAoAUIhhGACRHyQQDS2gOAaLSpQRFyotEvZx50747mz2U2UzmZ37sx898z5zvdYYQ9gIjQa4PFjUYDhr+JOFmmxDnmUR8s+CZWaA+H58AxtZTdsrhoyNcWZRLk/0Lnyctkcqw9Nguc/TtspRLwLqdKIbp3H7pcGKzYLTirUZWfxzhEQ1uIOenC4ORufK1IJ4WMCn9fyuwS8nhQjmu6h6VohCBuPRNAIiG9JzIOJbqHoTUcQvAfpv+IsIv06rT5D29EqwGyC57XSdiy8ACCQG7aqZFNaAfXZTRDBOxBa0AlgVH4lj7riY0+04qZoQRe/K4791bjmq5szddEqycuW4CDGlzFgvTbIgLrKeKsD+5/vJ4GZJAlEFFaoi4hVhd5BZTfSpgamkEWRSurCdbK7C8/+XsFrPTAZVqzny3SdxKkAMeh8aXjlh3huaj9zrPn0B5e39TYhlLKaak5z561HqjkvhdHEKeZg7A4s2DeKwJNgDflhAKmwF0n1MO1WOKI2ynB/KaRuRNKbC1t4Eb5/1uG5n8RqqKCajhHARg4H4ezy+X+IoV8MDupU95sX3sCevmdQyHklD4ReCE89yNCQmP4ae5rPY3LVZHo5zgEJex31PRnoJEOn6HqsgKd4PhJ5ncX2xVewc/kR7FiccfYtdEqYOHU4RA/zq8c9WjkDrbtjEZhN/x9SnzAk8PbF9egamDejpaWFYWUYmVqUXEMmtrpXdDidJAJHFrYX7e0GSqapaprhpgIMpScPInHfdrR90YhFmxOVq1RyGXPqEa7pgfmcyp9wOEI8AFHjonQvsbw5jSiXzxSq8W56zUNnRjw3hZ4/HUPAFNOoyn5ZuiCa4fLN5Yb82e11NP1NJzbQgR5ImeUcQHprIHAA46YudDazt/kssHXOUV1khfpHCPxjnA+sXMVUeSy2u5fYyNG/UtpeiAiX7tTXXdUTmfSK8gpLrzqwZdUdd4EVzCeCOafDErH4oHPpSeQj5ln0CZXo40W80KtlA/oIMamZtbO4Mb+UU6YbJgy5vgEdRq4ApHR5xnwYZrQd3gyV3MhqypPPIIR/P6y+QjeexM5l11z1BcnTbBVPMOcM+c3Dbfa10eFYRIV/iFhEZrxgO3gBQnZSOaaD6YcRVNl+DJV4GRH5QNwgmT/5kGD+84zdICp242xhrhqGFrfMN6yWDQxBkl4nna6RPYRdy685+5Qawyqv54Vc2gEofQl1YQPC1Gss5+8gwgzqB302YTbk+MMuIqJzkLYWRi0pkcIQHWOv8ZhXlF3nWe0Ri8KbijnBhOGJWf972HCAgGPiCMKYLF/eVXFCJ9jV7ViXsB49zutbMKn5UP5b7PR5WDVIh+KqrCGjAu2uoig/ZSUuQ8IfzzATs3iM6p6qYMI+xWa8gs6OQU48OjyxcKgPieQWVs4092JY+AlhvrsCEv8tSXmI7ZlBC89i/6oIa7uuUY3t3K9jyBroyBB1OI7IXmAvO45k5hyKNc+yB+7lmYGOPsTe5nQFs/XgKVZ1wa2Nrh2eWHzRq0e3Mn/498IReP3o5F55+EkWSO59t0wkbru5Qf+Ay14fc6WOYWtgiIcYpl8YrivoaBpC+74Al6JtjASP2FRHZ34rw7l5lLqAXKGEKfzwX041B+CPaSOpAAAAAElFTkSuQmCC);
        width: 40px;
        height: 20px;
        right: 0;
        bottom: 12px; }

.nw-blue-link {
  background: none;
  border: none;
  color: #3485ff;
  font-size: 14px;
  flex-shrink: 0;
  text-align: left;
  padding: 0; }

.agreement {
  display: block;
  margin-top: 30px; }
  .agreement article {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    font-size: 14px; }
  .agreement__checkbox {
    align-self: flex-start;
    margin-right: 15px; }
  .agreement__text {
    flex-grow: 1; }
    .agreement__text p {
      margin: 0px;
      line-height: 24px; }
    .agreement__text h4 {
      font-weight: 400;
      color: #3485ff;
      margin: 10px 0px 0px; }

#update-notification {
  position: fixed;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  width: 100%;
  bottom: -100%;
  padding: 20px 20px 60px;
  color: #000000;
  background: #ffffff;
  z-index: 99999;
  -webkit-transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
  text-align: center;
  font-size: 92%;
  box-shadow: 0 -3px 6px rgba(35, 35, 35, 0.15); }
  #update-notification .nw-spinner {
    display: none; }
  #update-notification.show {
    bottom: 0; }
  #update-notification.clicked .nw-spinner {
    display: block; }
  #update-notification.clicked button > span {
    display: none; }
  #update-notification button {
    padding: 0 20px;
    margin: 10px auto;
    min-width: 128px; }
    #update-notification button svg {
      width: 18px; }

.nw-spinner {
  position: relative;
  width: 100%; }
  .nw-spinner__loading {
    display: none;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
  .nw-spinner--spinning {
    min-height: 100%; }
    .nw-spinner--spinning .nw-spinner__loading {
      display: block; }
  .nw-spinner svg circle {
    stroke: #3485ff; }

.my-portfolio {
  color: #000000; }
  .my-portfolio:hover, .my-portfolio:focus {
    outline: none;
    color: #000000; }
  .my-portfolio + .nw-subTitle {
    margin-top: 20px; }

.nw-my-goals .nw-progress-bar {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.nw-my-goals .nw-my-goals__child:nth-child(even) {
  margin-top: 20px; }

.nw-home-header {
  padding-bottom: 20px;
  text-align: center;
  color: #ffffff;
  background-color: #3485ff;
  background-image: url(/static/media/homeheader.e8b78008.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  margin-top: 0;
  margin-bottom: 20px;
  min-height: 240px; }
  .nw-home-header .nw-top-nav {
    margin: 0; }
  .nw-home-header h1 {
    margin-top: 20px;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;
    color: white; }
  .nw-home-header__value {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px; }
  .nw-home-header__change {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    justify-content: center;
    align-items: center;
    font-size: 14px; }
    .nw-home-header__change span {
      margin-left: 5px; }
    .nw-home-header__change-percent {
      padding: 7px 15px;
      font-weight: 300;
      background: #00c693;
      border-radius: 16px;
      -o-border-radius: 16px;
      margin-right: 15px; }
    .nw-home-header__change-value {
      font-weight: 300; }

.nw-homepage-body {
  padding: 0 20px 20px;
  background: #f7f7f7; }

.nw-home-tabs {
  margin-bottom: 30px; }
  .nw-home-tabs .tabs-menu__tabs {
    justify-content: center; }
  .nw-home-tabs .tabs-menu__content {
    padding: 0; }
  .nw-home-tabs .nw-button {
    width: 100%; }

.catalogue-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  -webkit-transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
  opacity: 0;
  pointer-events: none; }
  .catalogue-filter--open {
    opacity: 1;
    background: rgba(0, 0, 0, 0.4);
    pointer-events: auto; }
    .catalogue-filter--open .catalogue-filter__container {
      right: 0; }
  .catalogue-filter__container {
    height: 100vh;
    width: 300px;
    box-shadow: 4px 0 20px rgba(21, 24, 30, 0.1);
    background: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    right: -100%;
    -webkit-transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: 500ms cubic-bezier(0.77, 0, 0.175, 1); }
  .catalogue-filter .nw-button {
    margin: 30px auto;
    width: calc(100% - 40px); }

.nw-page-position .nw-card-position {
  padding-bottom: 45px; }
  .nw-page-position .nw-card-position .nw-card__body {
    height: 200px; }

.nw-page-position .nw-button + .nw-card {
  margin-top: 20px; }

.investment-actions {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  justify-content: space-between;
  margin-bottom: 20px;
  min-height: 48px; }
  .investment-actions .nw-button {
    margin-top: 0;
    flex-grow: 1; }
    .investment-actions .nw-button + .nw-button {
      margin-left: 20px; }


.transaction-filter {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  -webkit-transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
  transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
  opacity: 0;
  pointer-events: none; }
  .transaction-filter--open {
    opacity: 1;
    background: rgba(0, 0, 0, 0.4);
    pointer-events: auto; }
    .transaction-filter--open .transaction-filter__container {
      right: 0; }
  .transaction-filter__container {
    height: 100vh;
    width: 300px;
    box-shadow: 4px 0 20px rgba(21, 24, 30, 0.1);
    background: #ffffff;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    right: -100%;
    -webkit-transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: 500ms cubic-bezier(0.77, 0, 0.175, 1); }
  .transaction-filter .nw-button {
    margin: 30px auto;
    width: calc(100% - 40px); }
  .transaction-filter .filter-box__options--horizontal {
    padding-right: 20px; }

.risk-profiling-card .nw-card__body {
  display: block;
  font-size: 14px; }

.risk-profiling-card h2 {
  margin-top: 0;
  font-size: 24px;
  margin-bottom: 15px;
  text-transform: capitalize; }

.risk-profiling-card__date {
  color: #545454;
  margin-bottom: 15px; }

.risk-profiling-card__warning {
  color: #f45f32;
  margin-bottom: 15px; }

.risk-profiling-card p {
  line-height: 24px; }

.risk-profiling-card h4 {
  margin: 15px 0px; }

.nw-button {
  margin-top: 30px;
  width: 100%; }

.risk-scale {
  height: 140px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAUsAAAAUCAYAAAAEGB8IAAAAAXNSR0IArs4c6QAAAPpJREFUeAHt2zEOhDAQA0DEF/M6fovoU6zkAoTnShSj7Cxyd8da6zp+8Pv6HO6XfWT8+GUCWfr5/s7sFdIECBDoEFCWHXs2JQECoYCyDAHFCRDoEFCWHXs2JQECoYCyDAHFCRDoEFCWHXs2JQECoYCyDAHFCRDoEFCWHXs2JQECoYCyDAHFCRDoEBiX5fQfFG+dm67L/aZS+3P89i7Tp/ymUvtzb/qNy3J/dU8JECDQIaAsO/ZsSgIEQgFlGQKKEyDQIaAsO/ZsSgIEQgFlGQKKEyDQIaAsO/ZsSgIEQgFlGQKKEyDQIaAsO/ZsSgIEQgFlGQKKEyDQIXADf8s1iy1WaqYAAAAASUVORK5CYII=) repeat-x bottom left/contain;
  margin-bottom: 30px;
  flex-shrink: 0; }
  .risk-scale__marker {
    position: relative;
    display: inline-block;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%); }
    .risk-scale__marker img {
      width: 65px;
      height: auto; }
    .risk-scale__marker span {
      font-size: 20px;
      font-weight: bold;
      position: absolute;
      left: 50%;
      top: 25px;
      -webkit-transform: translateX(-50%);
              transform: translateX(-50%); }

.login-page {
  padding: 20px;
  min-height: 100vh;
  background: url(/static/media/backgroundLogin@2x.797ca01b.jpg) no-repeat center/cover;
  color: #ffffff; }
  .login-page .tabs-menu__tabs li {
    color: #ffffff;
    padding: 6px 28px; }
    .login-page .tabs-menu__tabs li.tabs-menu--active {
      border: 1px solid #ffffff; }
  .login-page .tabs-menu__content {
    padding: 0;
    -webkit-transform: translateY(50%);
            transform: translateY(50%); }
    .login-page .tabs-menu__content h2 {
      color: #ffffff;
      margin-top: 0;
      margin-bottom: 40px;
      font-size: 24px; }
    .login-page .tabs-menu__content label {
      /* OLD - iOS 6-, Safari 3.1-6 */
      /* OLD - Firefox 19- (buggy but mostly works) */
      /* TWEENER - IE 10 */
      /* NEW - Chrome */
      display: flex;
      /* NEW, Spec - Opera 12.1, Firefox 20+ */
      flex-direction: column;
      margin-bottom: 30px; }
      .login-page .tabs-menu__content label span {
        color: #ffffff; }
      .login-page .tabs-menu__content label > span {
        opacity: 0.7;
        margin-bottom: 5px; }
    .login-page .tabs-menu__content .nw-button {
      background: #ffffff;
      color: #3485ff;
      font-weight: bold;
      float: left; }

.login-tab__password {
  position: relative; }
  .login-tab__password input {
    padding-right: 60px; }
  .login-tab__password a {
    position: absolute;
    right: 0;
    bottom: 15px; }
    .login-tab__password a span {
      text-transform: uppercase; }

.login-tab .nw-button {
  width: 100%;
  margin-top: 15px; }

.nw-card-goals .nw-display--flex {
  flex-wrap: wrap;
  margin-top: -20px; }
  .nw-card-goals .nw-display--flex > * {
    margin-top: 20px; }
  .nw-card-goals .nw-display--flex .nw-icon-button {
    width: 50%; }

.nw-progress-bar {
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1); }

.nw-faq-tabs .tabs-menu__content {
  padding: 0; }

.nw-fund-factsheet-page {
  padding-bottom: 64px; }
  .nw-fund-factsheet-page .fund-summary .fund-summary__description section {
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    margin-top: 20px; }
    .nw-fund-factsheet-page .fund-summary .fund-summary__description section span {
      color: #00c693;
      margin: 0px 5px; }
    .nw-fund-factsheet-page .fund-summary .fund-summary__description section h4 {
      font-weight: 400;
      margin: 0; }
  .nw-fund-factsheet-page .fund-summary .fund-summary__description p {
    line-height: 21px; }
  .nw-fund-factsheet-page__performance {
    min-height: 84px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 4pt 18pt -12pt;
    /* OLD - iOS 6-, Safari 3.1-6 */
    /* OLD - Firefox 19- (buggy but mostly works) */
    /* TWEENER - IE 10 */
    /* NEW - Chrome */
    display: flex;
    /* NEW, Spec - Opera 12.1, Firefox 20+ */
    flex-direction: column;
    align-items: center; }
    .nw-fund-factsheet-page__performance p {
      color: #3485ff; }
    .nw-fund-factsheet-page__performance span {
      width: 20px;
      height: 2px;
      margin-top: 10px;
      background-color: #E6E6E6; }
  .nw-fund-factsheet-page__child {
    position: absolute;
    bottom: -100%;
    -webkit-transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
    transition: 500ms cubic-bezier(0.77, 0, 0.175, 1);
    box-shadow: rgba(0, 0, 0, 0.08) 0 0 4px;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background: #ffffff; }
    .nw-fund-factsheet-page__child::before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100%;
      background: rgba(0, 0, 0, 0.08);
      z-index: -1;
      opacity: 0;
      -webkit-transition: 1000ms cubic-bezier(0.77, 0, 0.175, 1);
      transition: 1000ms cubic-bezier(0.77, 0, 0.175, 1);
      pointer-events: none; }
    .nw-fund-factsheet-page__child--on {
      bottom: 0; }
      .nw-fund-factsheet-page__child--on::before {
        opacity: 1; }
    .nw-fund-factsheet-page__child span {
      width: 20px;
      height: 2px;
      margin-top: 10px;
      background-color: #E6E6E6; }
    .nw-fund-factsheet-page__child .fund-factsheet__menu {
      background: #ffffff;
      padding: 30px 15px;
      min-height: 380px; }
      .nw-fund-factsheet-page__child .fund-factsheet__menu .nw-card {
        box-shadow: unset;
        border: unset;
        padding: 0; }
      .nw-fund-factsheet-page__child .fund-factsheet__menu .past-performance__silder {
        padding: 20px;
        /* OLD - iOS 6-, Safari 3.1-6 */
        /* OLD - Firefox 19- (buggy but mostly works) */
        /* TWEENER - IE 10 */
        /* NEW - Chrome */
        display: flex;
        /* NEW, Spec - Opera 12.1, Firefox 20+ */
        flex-direction: column;
        align-items: center; }
        .nw-fund-factsheet-page__child .fund-factsheet__menu .past-performance__silder span {
          color: #00c693;
          background-color: transparent; }
      .nw-fund-factsheet-page__child .fund-factsheet__menu .fund-multiplier__list {
        /* OLD - iOS 6-, Safari 3.1-6 */
        /* OLD - Firefox 19- (buggy but mostly works) */
        /* TWEENER - IE 10 */
        /* NEW - Chrome */
        display: flex;
        /* NEW, Spec - Opera 12.1, Firefox 20+ */
        align-items: center;
        justify-content: space-evenly; }
        .nw-fund-factsheet-page__child .fund-factsheet__menu .fund-multiplier__list__item {
          /* OLD - iOS 6-, Safari 3.1-6 */
          /* OLD - Firefox 19- (buggy but mostly works) */
          /* TWEENER - IE 10 */
          /* NEW - Chrome */
          display: flex;
          /* NEW, Spec - Opera 12.1, Firefox 20+ */
          flex-direction: column;
          align-items: center; }
          .nw-fund-factsheet-page__child .fund-factsheet__menu .fund-multiplier__list__item .nw-button--filter {
            background-color: #ffffff;
            width: 85px;
            margin-top: -12px;
            margin-right: 0px; }
      .nw-fund-factsheet-page__child .fund-factsheet__menu .fund-multiplier__desc {
        /* OLD - iOS 6-, Safari 3.1-6 */
        /* OLD - Firefox 19- (buggy but mostly works) */
        /* TWEENER - IE 10 */
        /* NEW - Chrome */
        display: flex;
        /* NEW, Spec - Opera 12.1, Firefox 20+ */
        align-items: center;
        justify-content: center;
        margin-top: 10px; }
        .nw-fund-factsheet-page__child .fund-factsheet__menu .fund-multiplier__desc p {
          margin: 0px; }
        .nw-fund-factsheet-page__child .fund-factsheet__menu .fund-multiplier__desc h4 {
          color: #00c693;
          background-color: transparent;
          font-weight: 400;
          margin-left: 5px; }

.nw-page-otp {
  text-align: center;
  line-height: 24px; }
  .nw-page-otp .nw-page__body {
    padding-top: 60px; }
  .nw-page-otp p {
    color: #545454;
    margin: 0; }
  .nw-page-otp .nw-input-group {
    margin-top: 30px; }
  .nw-page-otp button {
    background: none;
    border: none;
    color: #3485ff;
    margin-top: 30px;
    outline: none; }

.nw-page-buyFund .nw-page__body {
  font-size: 14px; }

.nw-page-buyFund__note {
  font-size: 14px;
  line-height: 24px; }

.nw-page-buyFund .nw-mutual-fund {
  overflow: visible; }

.nw-debit-card {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  justify-content: space-between; }
  .nw-debit-card .nw-input-box {
    max-width: 48%; }

.nw-page-payment-complete__orange {
  color: #f45f32; }

.nw-page-payment-complete button {
  margin-top: 20px; }

.nw-page-payment-complete .nw-button--complete {
  color: #ffffff;
  border-color: #fcd53b;
  background-color: #fcd53b; }

.nw-page-payment-complete h3 {
  font-size: 16px;
  margin: 20px 0px; }

.nw-page-payment-complete section {
  font-size: 14px; }
  .nw-page-payment-complete section p {
    margin: 0px; }

.nw-page-payment-complete h4 {
  text-align: center;
  font-weight: 400;
  margin: 10px 0px 0px; }

.nw-page-sellFund .nw-card__header {
  font-weight: bold; }

.nw-page-sellFund__tos {
  line-height: 24px;
  align-items: flex-start; }
  .nw-page-sellFund__tos .nw-option-box__text {
    text-transform: none; }

.nw-page-sellFund .nw-blue-link {
  margin-left: 44px; }

.nw-page-sellFund .nw-redemption-account {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  justify-content: space-between; }
  .nw-page-sellFund .nw-redemption-account .nw-input-box {
    width: 50%; }
    .nw-page-sellFund .nw-redemption-account .nw-input-box + .nw-input-box {
      margin-left: 15px; }

.nw-page-sellFund .nw-button {
  width: 100%; }

.nw-page-sellFund .nw-item--nav {
  border-bottom: 2px solid rgba(0, 0, 0, 0.1); }

.nw-page-sellFund .nw-item--currentValue .nw-item__value {
  color: #00c693; }

.nw-page-orderReceived {
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 90px 20px 60px 20px;
  text-align: center;
  height: 100vh; }
  .nw-page-orderReceived img {
    width: 148px;
    height: auto;
    margin-bottom: 90px; }
  .nw-page-orderReceived h2 {
    margin-top: 0;
    margin-bottom: 15px;
    font-size: 24px; }
  .nw-page-orderReceived p {
    line-height: 24px;
    color: #545454;
    margin-top: 0; }
    .nw-page-orderReceived p + span {
      flex-grow: 1; }
  .nw-page-orderReceived .nw-button {
    width: 100%; }

.App {
  height: 100vh;
  background: -webkit-gradient(linear, left top, left bottom, from(white), color-stop(90%, #e0e0e0), color-stop(90%, #eaeaea), to(white));
  background: -webkit-linear-gradient(top, white 0%, #e0e0e0 90%, #eaeaea 90%, white 100%);
  background: linear-gradient(180deg, white 0%, #e0e0e0 90%, #eaeaea 90%, white 100%);
  /* OLD - iOS 6-, Safari 3.1-6 */
  /* OLD - Firefox 19- (buggy but mostly works) */
  /* TWEENER - IE 10 */
  /* NEW - Chrome */
  display: flex;
  /* NEW, Spec - Opera 12.1, Firefox 20+ */
  -ms-overflow-style: none;
  scrollbar-width: none; }
  .App ::-webkit-scrollbar {
    display: none; }
  .App * {
    -ms-overflow-style: none;
    scrollbar-width: none; }
    .App * ::-webkit-scrollbar {
      display: none; }
  .App__body {
    background: #f7f7f7;
    min-height: 100vh;
    position: relative;
    scroll-behavior: smooth;
    width: 100%; }
    .App__body:before {
      position: absolute;
      content: "";
      background: #3485ff;
      width: 100%;
      height: 64px; }
    .App__body--desktop {
      max-width: 400px;
      min-height: 94vh;
      box-shadow: 4px -2px 16px 0px rgba(0, 0, 0, 0.225);
      border-radius: 24px;
      -o-border-radius: 24px;
      margin: auto;
      position: relative;
      overflow-x: hidden; }
      .App__body--desktop:before {
        display: none; }
      .App__body--desktop #update-notification {
        max-width: 400px;
        left: 50%;
        -webkit-transform: translateX(-50%);
                transform: translateX(-50%); }
      .App__body--desktop .modal {
        max-width: 400px;
        max-height: 94vh;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
        border-radius: 24px;
        -o-border-radius: 24px; }
      .App__body--desktop .nw-page {
        position: relative; }
        .App__body--desktop .nw-page .navbar {
          position: absolute; }

